import React, { useCallback, useEffect, useState, useRef } from "react";
import {Form, List, Table, Tooltip, Popover as Pop} from "antd";
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { Container, Header, Content } from "./styles";
import {formatCurrency, formatDateToView} from "../../../../utils/helpers";

const columnsApportionmentWithInstallment = [
  {
    title: "Id Projeto",
    dataIndex: "projectIdERP",
  },
  {
    title: "Projeto",
    dataIndex: "projectName",
    onHeaderCell: () => ({
      style: { minWidth: 220 },
    }),
  },
  {
    title: "Linha",
    dataIndex: "lineIdErp",
    onHeaderCell: () => ({
      style: { minWidth: 220 },
    }),
    render: (_: any, record: any) => (
      <Pop content={record?.lineIdErp} title="Linha - Descrição">
        {`${record?.lineIdErp?.substring(0, 22)}...`}
      </Pop>
    ),
  },
  {
    title: "Percentual Rateio (%)",
    dataIndex: "percentage",
    render: (_: any, record: any) => {
      return `${record.percentage?.toFixed(2)} %`;
    },
  },
]

const ApportionmentInformation = ({ data, toggle, resetStatusToggle, record }: any) => {
  const [items, setItems] = useState([]);
  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm<any>();
  const isFirstRender = useRef(true);

  const handleOpen = () => {setIsOpen(!isOpen); resetStatusToggle()};

  // const getApportionmentInformation = async () => {
  //   const columnsInstallment: any = []

  //   console.log(record)

  //   const sortedItems = {
  //     ...data?.projectRequest,
  //     items: data?.projectRequest?.items?.map((item: any) => ({
  //       ...item,
  //       apportions: item?.apportions?.sort((a: any, b: any) => {
  //         const dateA: any = new Date(a.maturityApportion);
  //         const dateB: any = new Date(b.maturityApportion);
  //         return dateA - dateB;
  //       })
  //     }))
  //   }

  //   sortedItems.items[0]?.apportions?.forEach((apportion: any, index: any) => {
  //     columnsInstallment.push({
  //       title: `${index + 1}° Parcela \n ${formatDateToView(apportion.maturityApportion)}`,
  //       dataIndex: `${index + 1}installment`,
  //       render: (apportion: any, record: any) => {
  //         return <span>{formatCurrency(record?.apportions[index]?.valueApportion)}</span>
  //       }
  //     });
  //   });

  //   const updatedColumns = [
  //     ...columnsApportionmentWithInstallment,
  //     ...columnsInstallment,
  //     {
  //       title: "Total",
  //       dataIndex: "totalValue",
  //       render: (_: any, record: any) => {
  //         return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.valueApportion, 0))}</span>
  //       }
  //     },
  //   ]

  //   setColumnsInstallment(updatedColumns)

  //   setItems(data?.projectRequest.items)

  // };

  const getApportionmentInformation = async () => {

    const columnsInstallment: any = []

    const sortedItems = {
      ...record,
      items: record?.items?.map((item: any) => ({
        ...item,
        apportions: item?.apportions?.sort((a: any, b: any) => {
          const dateA: any = new Date(a.dueDate);
          const dateB: any = new Date(b.dueDate);
          return dateA - dateB;
        })
      }))
    }

    console.log(sortedItems)

    sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {

      let totalValue = 0;

      sortedItems?.items?.forEach((item: any) => {
          item.apportions.forEach((apport: any) => {
              if (apport.dueDate === apportion.dueDate) {
                  totalValue += apport.value;
              }
          });
      });

      columnsInstallment.push({
        title: `${index + 1}° Parcela \n ${formatDateToView(apportion.dueDate)} ${formatCurrency(totalValue)}`,
        dataIndex: `${index + 1}installment`,
        render: (apportion: any, record: any) => {
          return <span>{formatCurrency(record?.apportions[index]?.value)}<br />{record?.apportions[index]?.idErpCorrelation}</span>
        }
      });
    });

    const updatedColumns = [
      ...columnsApportionmentWithInstallment,
      ...columnsInstallment,
      {
        title: `Total ${formatCurrency(sortedItems?.items?.reduce((sum: any, item: any) => sum + item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0), 0))}`,
        dataIndex: "totalValue",
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0))}</span>
        }
      },
    ]

    setColumnsInstallment(updatedColumns)
    
    let mappedRecord: any = []

    sortedItems?.items?.forEach((item: any) => {

      mappedRecord = [ ...mappedRecord, {
        projectIdERP: item?.projectIdErp,
        projectName: item?.projectName,
        lineIdErp: `${item?.projectRevisionItem?.lineIdErp} - ${item?.projectRevisionItem?.description}`,
        percentage: item?.percentage,
        apportions: item.apportions
      }]

    });

    setItems(mappedRecord)
  }

  const initialValues = {

  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if(toggle === data?.orderId) {
      setIsOpen(true)
    }
    
  }, [toggle])

  useEffect(() => {
    getApportionmentInformation();
  }, [isOpen]);

  return (
    <Container>
      <Header onClick={handleOpen}>
        <text>Informações do Rateio e cronograma de pagamento</text>
        {isOpen ? <IoIosArrowDown /> : <IoIosArrowBack />}
      </Header>
      {isOpen && (
        <Content>
          <List size="small">
            <Form form={form} layout="vertical" initialValues={initialValues}>
              <Table
                  columns={columnsInstallment}
                  dataSource={items}
                  pagination={false}
              />
            </Form>
          </List>
        </Content>
      )}
    </Container>
  );
};

export default ApportionmentInformation;
