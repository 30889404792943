import styled from "styled-components";
import {TextareaHTMLAttributes} from "react";

interface TProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    disabled?: boolean;
    bordered?: boolean;
}

interface ColCancelProps {
    size?: 'half' | 'full';
}

export const BoxInfoCancel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RowCancel = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const ColCancel = styled.div<ColCancelProps>`
  flex: 1;
  max-width: ${(props:any ) => (props.size === 'half' ? '25%' : '50%')};
  flex-basis: ${(props:any) => (props.size === 'half' ? '25%' : '50%')};
`;

export const DivSTyled = styled.div<TProps>`
  background-color: #ffffff;

  * {
    color: black; // Define a cor do texto para preto
    background-color: #ffffff;
    //opacity: 0.5; 
    //pointer-events: none; 
  }

`;

export const TextInput = styled.input`
  width: ${(props) => props.width}px;
  box-sizing: content-box;
  font-size: 16px;
  font-family: Arial, sans-serif;
  padding: 4px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 8px;
  font-size: 16px;
  overflow: auto;
  resize: none;
  box-sizing: border-box;
`;