import React, {useEffect, useMemo, useState} from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Rate,
  Table,
  Tag,
  Upload,
  Modal,
} from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Navigate, useNavigate, useParams } from "react-router";
import {
  AreaField,
  CheckField,
  DateField,
  SearchField,
  Section,
  SelectField,
  TextField,
  CurrencyField,
  NumberField,
} from "components";
import {formatCurrency, removeCurrency} from "utils/helpers";
import {
  useApprovalHierarchy,
  useBusinessUnits,
  useProduct,
  useSuppliers,
} from "repositories";
import { useAuth, usePagination } from "hooks";
import Row from "components/Grid/Row";
import Col from "components/Grid/Col";
import { UploadWrapper, WrapperCurrency } from "./styles";
import { DefaultOptionType } from "antd/lib/select";
import moment from "moment";
import {
  supplierStatus,
  SupplierStatus,
  ProductType,
  SupplierType,
} from "../../../../utils/constants";
import {log} from "util";
import NormalizedCurrencyField from "../../../../components/Form/NormalizedCurrencyField";

const { Dragger } = Upload;
/* Trecho comentado o campo agora será fixo com o valor projeto */
// const purchaseTypeOptions = [
//   { name: "Compra normal", id: "C" },
//   { name: "Projeto", id: "P" },
//   { name: "Verba", id: "V" },
//   { name: "Reembolso", id: "R" },
//   { name: "Prestação de contas", id: "PC" },
// ];

const timingOptions = [
  { name: "Planejada", id: 1 },
  { name: "Não planejada", id: 2 },
];
const providerTypeOptions = [
  { name: "Freelancer", id: 1 },
  { name: "Fornecedor", id: 2 },
];
{
  /*// campo comentado pois será fixo despesa*/
}
// const ExpenseOrInvestmentOptions = [
//   { name: "Despesa", id: 1 },
//   { name: "Investimento", id: 2 },
// ];

export type TOptions = {
  id: number | string;
  name: string;
};

const convertToOptions = (items: TOptions[]) =>
  items.map(
    (item) =>
      ({
        label: item.name,
        value: item.id,
      } as DefaultOptionType)
  );

const steps = [
  {
    title: "Projeto",
    content: "First-content",
    description: "Selecione o Projeto",
  },
  {
    title: "Itens",
    content: "Second-content",
    description: "Escolha as linhas / produtos",
  },
  {
    title: "Complementos",
    content: "Last-content",
    description: "Preencha o formulário",
  },
  {
    title: "Freelancers",
    content: "Last-content",
    description: "Preencha o formulário Funcional",
  },
];

const formatDate = (data: Date) => {
  const date = new Date(data);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adicione +1 porque os meses são zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const FormRequest = ({
  productsLinesSelected,
  project,
  selectDataForm,
  nextStep,
  prevStep,
  handleProviderType,
  createRequest,
  currentDataForm,
  loading,
}: any) => {
  const columnsLines: any = [
    // {
    //   title: "Id Compras",
    //   dataIndex: "id",
    // },
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      // oq é ?
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
          <Popover
            content={record.technicalScopeDescription}
            overlayStyle={{ maxWidth: "800px" }}
          >
            {record.technicalScope}
          </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
    },
    {
      title: "Diárias",
      dataIndex: "daily",
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.totalCost);
      },
    },
    {
      title: "Já tem requisições?",
      dataIndex: "withoutRequest",
      align: "center",
      render: (_: any, record: any) => {
        return record.withoutRequest ? (
          <Tag
            color="green"
            style={{ cursor: "pointer" }}
            onClick={() => openRequests(record.projectRequestItems)}
          >
            Sim
          </Tag>
        ) : (
          <Tag color="default">Não</Tag>
        );
      },
    },
    // {
    //   title: "Alvo de pacote ou rateio?",
    //   dataIndex: "packageOrApportionment",
    //   align: "center",
    //   render: (_: any, record: any) => {
    //     return record.packageOrApportionment ? (
    //       <Tag color="green">Sim</Tag>
    //     ) : (
    //       <Tag color="default">Não</Tag>
    //     );
    //   },
    // },
    {
      // soma da quantidade + valor
      title: "Requisições",
      dataIndex: "approvalRequests",
    },
    {
      title: "Saldo da linha",
      dataIndex: "lineBalance",
    }
  ];
  const columnsLinesEditable: any = [
    {
      title: "Id da linha TOTVS",
      dataIndex: "idLinha",
    },
    {
      title: "Tipo do Fat.",
      dataIndex: "paymentType",
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
    },
    {
      // oq é ?
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Escopo",
      dataIndex: "technicalScope",
      key: "technicalScope",
      render: (_: any, record: any) => {
        return (
            <Popover
                content={record.technicalScopeDescription}
                overlayStyle={{ maxWidth: "800px" }}
            >
              {record.technicalScope}
            </Popover>
        );
      },
    },
    {
      title: "Quant",
      dataIndex: "amount",
      render: (_: any, record: any) => {
        const editable = isEditingAmount(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name="amount" initialValue={record.amount}>
                <TextField name="amount" label="" textHelp="Quant" />
              </Form.Item>
            </Form>
        ) : (
            record.amount
        );
      },
    },
    {
      title: "Diárias",
      dataIndex: "daily",
      render: (_: any, record: any) => {
        const editable = isEditingDaily(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name="daily" initialValue={record.daily}>
                <NumberField name="daily" label="" textHelp="Dia(s)" />
              </Form.Item>
            </Form>
        ) : (
            record.daily
        );
      },
    },
    {
      title: "Custo Unítario",
      dataIndex: "unitCost",
      render: (_: any, record: any) => {
        const editable = isEditingUnitCost(record);
        return editable ? (
            <Form form={form}>
              <Form.Item name="unitCost" initialValue={record.unitCost}>
                <NormalizedCurrencyField
                    name="valor"
                    label=""
                    placeholder="0,00"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                />
              </Form.Item>
            </Form>
        ) : (
            formatCurrency(record.unitCost)
        );
      },
    },
    {
      title: "Custo total",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record.amount * record.daily * record.unitCost);
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) =>
          editModeButtonColumnsLine ? (
              <Button
                  disabled={suppliers.length === 0}
                  onClick={() => {
                    setEditModeButtonColumnsLine(false);
                    handleEdit(record.key);
                  }}
              >
                Editar
              </Button>
          ) : (
              <Button
                  onClick={() => {
                    const params = {
                      amount: form.getFieldValue("amount"),
                      daily: form.getFieldValue("daily"),
                      unitCost: form.getFieldValue("unitCost"),
                    };
                    handleSaveProductLine(params);
                    setEditingKeyUnitCost(null);
                    setEditingKeyDaily(null);
                    setEditingKeyAmount(null);
                    setEditModeButtonColumnsLine(true);
                  }}
              >
                Salvar
              </Button>
          ),
    },
  ];
  function applyPhoneMaskWithNinthDigit(phoneNumber: any) {
    const onlyNumbers = phoneNumber.replace(/\D/g, ""); // Remove caracteres não numéricos

    let formattedNumber;
    if (onlyNumbers.length === 11) {
      formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{5})(\d{4})/,
        "($1) $2-$3"
      );
    } else if (onlyNumbers.length === 10) {
      formattedNumber = onlyNumbers.replace(
        /(\d{2})(\d{4,5})(\d{4})/,
        "($1) $2-$3"
      );
    } else {
      // Tratar casos com números de telefone inválidos
      return phoneNumber;
    }

    return formattedNumber;
  }
  const columnsFreelancer: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Preciario",
      dataIndex: "preciary",
      align: "center",
      render: (_: any, record: any) => {
        if (record.preciary) {
          return <Tag>Sim</Tag>;
        } else {
          return <Tag>Não</Tag>;
        }
      },
    },
    {
      title: "Avaliações",
      dataIndex: "currentAmountEvaluation",
      align: "center",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Rate disabled value={record.currentEvaluation} />
          <span>{`(${record.currentAmountEvaluation})`}</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => setSuppliers([])}>Excluir</Button>
      ),
    },
  ];
  const columnsSuppliers: any = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Razão Social",
      dataIndex: "corporateName",
    },
    {
      title: "Nome fantasia ",
      dataIndex: "fantasyName",
    },
    {
      title: "Tipo de Prestador",
      dataIndex: "typeSupplier",
      render: (_: any, record: any) =>
        record.typeSupplier === SupplierType.SUPPLIER
          ? "Fornecedor"
          : "Freelancer",
    },
    {
      title: "Telefone",
      dataIndex: "telephone",
      render: (_: any, record: any) => {
        return applyPhoneMaskWithNinthDigit(record.telephone);
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
    },
    {
      title: "Status Homologação",
      dataIndex: "status",
      render: (_: any, record: any) => {
        if (record.status === SupplierStatus.APPROVED) {
          return <Tag color="green">Homologado</Tag>;
        }

        if (record.status === SupplierStatus.NOT_APPROVED) {
          return <Tag color="red">Bloqueado</Tag>;
        }

        if (record.status === SupplierStatus.PRE_REGISTRATION) {
          return <Tag color="blue">{supplierStatus[record.status]}</Tag>;
        }

        return <Tag>{supplierStatus[record.status]}</Tag>;
      },
    },
    {
      title: "Preciario",
      dataIndex: "preciary",
      align: "center",
      render: (_: any, record: any) => {
        if (record.preciary) {
          return <Tag>Sim</Tag>;
        } else {
          return <Tag>Não</Tag>;
        }
      },
    },
    {
      title: "Valor negociado",
      dataIndex: "value",
      align: "center",
      render: (_: any, record: any) => (
        <span>{formatCurrency(record.value)}</span>
      ),
    },
    {
      title: "Unidade de medida",
      dataIndex: "unitOfMeasurement",
      align: "center",
    },
    {
      title: "Avaliações",
      dataIndex: "currentAmountEvaluation",
      align: "center",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Rate disabled value={record.currentEvaluation} />
          <span>{`(${record.currentAmountEvaluation})`}</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => setSuppliers([])}>Excluir</Button>
      ),
    },
  ];
  const [form] = Form.useForm();
  const [suppliers, setSuppliers] = useState<any>([]);
  const [chosenProviderType, setChosenProviderType] = useState<boolean>(true);
  const [suppliersSearch, setSuppliersSearch] = useState<any>([]);
  const [productByPriceRange, setProductByPriceRange] = useState<any>([]);
  const [seniority, setSeniority] = useState<string>("");
  const [seniorityData, setSeniorityData] = useState<any>({});
  const [responseProductData, setResponseProductData] = useState<any>({});
  const [endDate, setEndDate] = useState<any>(moment());
  const [startDate, setStartDate] = useState<any>(moment());
  const [productsLines, setProductsLines] = useState<any>(
    productsLinesSelected
  );
  const [ productsLinesNotEdited, setProductsLinesNotEdited ] = useState<any>(productsLinesSelected);
  const [optionsSearchSuppliers, setOptionsSearchSuppliers] = useState<any>([]);
  const [mappedSuppliers, setMappedSuppliers] = useState<any>([]);
  const [suppliersId, setSuppliersId] = useState<any>("");
  const [recordUpdateLine, setRecordUpdateLine] = useState<any>("");
  const [businessUnit, setBusinessUnit] = useState<any>(0);
  const [transformedType, setTransformedType] = useState("");
  const [editModeButtonColumnsLine, setEditModeButtonColumnsLine] =
    useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");

  const [unico, setUnico] = useState(0);
  const [provider, setProvider] = useState(0);

  const [forceUpdate, setForceUpdate] = useState(false);

  const [filesItems, setFilesItems] = useState<any>([]);
  const timingOptionsConvert = convertToOptions(timingOptions);

  /* Trecho comentado o campo agora será fixo com o valor projeto */
  // const purchaseTypeOptionsConvert = convertToOptions(purchaseTypeOptions);
  const providerTypeOptionsConvert = convertToOptions(providerTypeOptions);
  {
    /*// campo comentado pois será fixo despesa*/
  }
  // const ExpenseOrInvestmentOptionsConvert = convertToOptions(
  //   ExpenseOrInvestmentOptions
  // );

  const [editingKeyAmont, setEditingKeyAmount] = useState(null);
  const [editingKeyUnitCost, setEditingKeyUnitCost] = useState(null);
  const [editingDaily, setEditingKeyDaily] = useState(null);
  const isEditingDaily = (record: any) => record.key === editingDaily;
  const isEditingUnitCost = (record: any) => record.key === editingKeyUnitCost;
  const isEditingAmount = (record: any) => record.key === editingKeyAmont;

  const [btnClick, setBtnClick] = useState("");

  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const requestsColumns: any = [
    // {
    //   title: "Id Requisição TOTVS",
    //   dataIndex: "buId",
    // },
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "client",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
      render: (_: any, record: any) => {
        return formatCurrency(record?.totalCost);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const openRequests = (requests: any) => {
    setRequestModal(requests);
    setIsModalOpen(true);
  };

  const handleEdit = (key: React.SetStateAction<null>) => {
    setEditingKeyAmount(key);
    setEditingKeyDaily(key);
    setEditingKeyUnitCost(key);
    const record = productsLinesSelected.find(
      (item: { key: React.SetStateAction<null> }) => key === item.key
    );
    setRecordUpdateLine(record);
  };
  const normalizeValue = (value: any) => {
    value = value.trim();
    value = value.replace(/,/g, '.');
    let parts = value.split('.');
    if (parts.length > 2) {
      value = parts.slice(0, -1).join('') + '.' + parts.slice(-1);
    }
    value = value.replace(/[^\d.]/g, '');
    if (!value.includes('.')) {
      value = value.slice(0, -2) + '.' + value.slice(-2);
    }
    return parseFloat(value);
  };

  const handleSaveProductLine = async (params: any) => {
    const productId = recordUpdateLine.productId;
    let productLine = productsLinesSelected.find(
      (item: any) => item.productId === productId
    );
    if (params.amount) productLine.amount = Number(params?.amount);
    if (params.daily) productLine.daily = Number(params?.daily);
    const productT = await repositoryProduct.findProductById(productId);

    const formattedUnitCost  = normalizeValue(params.unitCost)

    const preciaryExist = !!productByPriceRange?.preciary
    const { preciary } = productT

    if (Number(params.unitCost) === 0 ){
      message.warn(
          `Valor de custo unitário informado é 0.`
      );
    }

    if (preciaryExist && params.unitCost && typeof formattedUnitCost !== "number") {
      if (
        provider === 2 &&
        preciaryExist &&
        productByPriceRange.value >= parseFloat(formattedUnitCost)
      ) {
        productLine.unitCost = formattedUnitCost
      } else if (
        provider === 1 &&
        preciary &&
        formattedUnitCost <= seniorityData.unitMaximunPrice
      ) {
        productLine.unitCost = formattedUnitCost
      } else {

        if (
            preciaryExist &&
            provider === 2 &&
            productByPriceRange.value < formattedUnitCost
        )
          message.warn(
            `Valor de custo unitário maior que o valor negociado. Tente outro valor.`
          );
        if (
          provider === 1 &&
          preciary &&
          !(formattedUnitCost < seniorityData.unitMaximunPrice)
        ) {
          message.warn(
            `Valor acima do preço máximo definido para a senioridade. Tente novamente.`
          );
        }
      }
    } else {
      if (
        provider === 2 &&
        preciaryExist &&
        productByPriceRange.value >= formattedUnitCost
      ) {
        productLine.unitCost = formattedUnitCost
      }  else if (
          provider === 2 &&
          preciaryExist
      ){
        message.warn(
          `Valor de custo unitário maior que o valor negociado. Tente outro valor.`
        );
      }
      if (
          provider === 1 &&
          preciary &&
          formattedUnitCost <= seniorityData.unitMaximunPrice
      ) {
        productLine.unitCost = formattedUnitCost
      }  else if (
          provider === 1 &&
          preciary
      ){
        message.warn(
          `Valor de custo unitário maior que o valor negociado. Tente outro valor.`
        );
      }


      if (
          provider === 2 &&
          !preciaryExist
      ) {
        productLine.unitCost = formattedUnitCost;
      }else if (
        provider === 1 &&
        !preciary
      ){
        productLine.unitCost = formattedUnitCost;
      }
    }

    if (
        provider === 1 &&
        !preciaryExist &&
        formattedUnitCost &&
        formattedUnitCost <= seniorityData.unitMaximunPrice
    ) {
      productLine.unitCost = formattedUnitCost
    }

    setProductsLines([productLine]);
  };

  const SenioridadeOptionsConvert = [
    {
      label: "Júnior",
      value: "JUNIOR",
    },
    {
      label: "Pleno",
      value: "PLENO",
    },
    {
      label: "Sênior",
      value: "SENIOR",
    },
  ];

  const productTypeOptions = [
    {
      value: "MC",
      label: "Commodities",
    },
    {
      value: "CE",
      label: "Estratégico",
    },
  ];

  const { roles: authRoles, hasRole } = useAuth();
  const repositoryBU = useBusinessUnits();
  const repositoryProduct = useProduct();
  const repositorySupplier = useSuppliers();
  const [isNotHomologated, setIsNotHomologated] = useState(false);
  // const repositoryProjectLine = useProjectItems();
  const navigate = useNavigate();
  const pagination = usePagination();

  const requestForm = async () => {
    const responseProduct = await repositoryProduct.findProductById(
      productsLinesSelected[0].productId
    );
    if (!responseProduct) return;

    const { minimumPaymentTermFreelance, minimumPaymentTermSupplier } =
      responseProduct;

    setResponseProductData(responseProduct);
    // Adiciona o tipo do produto
    switch (responseProduct.productType.typeKey) {
      case "MC":
        form.setFieldValue("productType", "MC");
        break;
      case "CE":
        form.setFieldValue("productType", "CE");
        break;

      default:
        form.setFieldValue("productType", "");
        break;
    }

    transformType(responseProduct.productType.typeKey);

    const paramsBU: any = {
      name: project.buName,
    };
    const responseBU = await repositoryBU.findBus(paramsBU);
    if (!responseBU) return;

    const mappedDataBU = responseBU?.content.map(
      (item: { id: any }) => item.id
    );
    setBusinessUnit(mappedDataBU);

    if (currentDataForm.providerType) {
      form.setFieldValue("providerType", currentDataForm.providerType);
      setForceUpdate(!forceUpdate);
    }

    if (currentDataForm.timing) {
      form.setFieldValue(
        "timing",
        currentDataForm.timing === "PLANEJADA" ? 1 : 2
      );
    }
    form.setFieldValue(
      "emission",
      currentDataForm.issueDate ? moment(currentDataForm.issueDate) : moment()
    );

    form.setFieldValue(
      "startDate",
      currentDataForm.serviceStartDate
        ? moment(currentDataForm.serviceStartDate)
        : startDate
    );
    form.setFieldValue(
      "endDate",
      currentDataForm.serviceEndDate
        ? moment(currentDataForm.serviceEndDate)
        : endDate
    );
    if(form.getFieldValue("timing") === 1){
      if(minimumPaymentTermFreelance > 0 && minimumPaymentTermSupplier > 0) {
        form.setFieldValue(
            "pay",
            moment(
                provider === 1 ?
                    startDate :
                    endDate
            ).add(
                provider === 1
                    ? minimumPaymentTermFreelance
                    : minimumPaymentTermSupplier,
                "days"
            )
        );
      }else {
        form.setFieldValue(
            "pay",
            moment(
                provider === 1 ?
                    startDate :
                    endDate
            ).add(
                provider === 1
                    ? 30
                    : 70,
                "days"
            )
        );
      }
    }

    form.setFieldValue("senioridade", currentDataForm.senioridade);
    form.setFieldValue("unitMinimunPrice", currentDataForm.unitMinimunPrice);
    form.setFieldValue("unitMaximunPrice", currentDataForm.unitMaximunPrice);
    if (currentDataForm.suppliers) {
      setSuppliers(currentDataForm.suppliers);
    }
    form.setFieldValue("technicalScope", currentDataForm.technicalScope);
    if (currentDataForm.files.length > 0) {
      setFilesItems(currentDataForm.files);
    }
    form.setFieldValue("objectiveFunction", currentDataForm.objectiveFunction);

    form.setFieldValue(
      "justifyIfItIsAnEmergency",
      currentDataForm.justifyIfItIsAnEmergency
    );

    form.setFieldValue(
      "JustifyReasonSingleSupplier",
      currentDataForm.JustifyReasonSingleSupplier
    );
    form.setFieldValue(
      "JustifyTheDueDateBeingShorter",
      currentDataForm.JustifyTheDueDateBeingShorter
    );
    form.setFieldValue(
      "JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate",
      currentDataForm.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate
    );
    form.setFieldValue(
      "JustifyUnapprovedSupplier",
      currentDataForm.JustifyUnapprovedSupplier
    );
  };
  useMemo(
      () => {
          const {amount ,daily ,unitCost, approvalRequests, lineBalance} = productsLinesNotEdited[0]
          const unitCostFormated = typeof(unitCost) === "string" ? parseFloat(unitCost.replace(/[^\d.,]/g, '')) : unitCost
          const productsLinesNotEditedFormated = {
            ...productsLinesNotEdited[0],
            approvalRequests: formatCurrency(approvalRequests),
            lineBalance: formatCurrency(lineBalance),
            unitCost: formatCurrency(unitCost),
            totalCost: formatCurrency(amount * daily * unitCostFormated)
          }
          setProductsLinesNotEdited([productsLinesNotEditedFormated])
      },
      []
  );
// Função para transformar o tipo usando o enum
  const transformType = (typeKey: string) => {
    const validKeys = Object.keys(ProductType) as Array<
      keyof typeof ProductType
    >;

    if (validKeys.includes(typeKey as keyof typeof ProductType)) {
      const transformedValue = ProductType[typeKey as keyof typeof ProductType];
      setTransformedType(transformedValue);
    }
    // } else {
    //   message.error("Tipo de produto inexistente");
    // }
  };

  const onChangeStartDate = (date: moment.Moment | null) => {
    const startDate = moment(date);
    const endDate = moment(form.getFieldValue("endDate"));
    const pay = moment(form.getFieldValue("pay"));
    const timing = form.getFieldValue("timing")
    setStartDate(startDate);
    initialValues.startDate = startDate;
    if (endDate.isBefore(startDate)) {
      setEndDate(startDate)
      form.setFieldValue("endDate", startDate);
      //if (pay.isBefore(startDate)) {
      //  form.setFieldValue("pay", startDate);
      //}
    }
    const { minimumPaymentTermFreelance, minimumPaymentTermSupplier } = responseProductData;
    if(minimumPaymentTermFreelance > 0 && minimumPaymentTermSupplier > 0) {
      if(timing === 1) {
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? minimumPaymentTermFreelance
                  : minimumPaymentTermSupplier,
              "days"
          )
        )
      }
    }else {
      if(timing === 1) {
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? 30
                  : 70,
              "days"
          )
        )
      }
    }
  };

  const onChangeEndDate = (date: moment.Moment | null) => {
    if (date === null) return moment()
    const endDate = moment(date);
    const timing = form.getFieldValue("timing")
    setEndDate(endDate);
    form.setFieldValue("endDate", endDate);

    const { minimumPaymentTermFreelance, minimumPaymentTermSupplier } = responseProductData;
    if(minimumPaymentTermFreelance > 0 && minimumPaymentTermSupplier > 0) {
      if(timing === 1){
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? minimumPaymentTermFreelance
                  : minimumPaymentTermSupplier,
              "days"
          )
        )
      }
    }else {
      if(timing === 1) {
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? 30
                  : 70,
              "days"
          )
        )
      }
    }
  };

  const onChangeIssueDate = (date: moment.Moment | null) => {
    if(date === null ) return moment()
    const paymentDate = moment(date);
    initialValues.pay = paymentDate;
  };

  /* Trecho comentado o campo agora será fixo com o valor projeto */
  // const onChangePurchaseType = (purchaseType: string | null) => {
  //   initialValues.purchaseType = String(purchaseType);
  // };

  const onChangeIndicationSupplier = (e: RadioChangeEvent | null) => {
    initialValues.indicationSingleSupplierJustifiable = String(e?.target.value);
    setUnico(e?.target.value);
  };

  const onChangetiming = (data: number) => {
    // if (data === 1) {
    //   form.setFieldsValue({
    //     pay: form.getFieldValue("endDate")?.clone()?.add(30, "days"),
    //   });
    // }
    const emissao = moment(form.getFieldValue("emission"));
    const start = moment(form.getFieldValue("startDate"));
    const endDate = moment(form.getFieldValue("endDate"));
    const provider = form.getFieldValue("providerType");
    if (data === 1 && start.isBefore(emissao)) {
      form.setFieldValue("startDate", emissao);
    }
    const { minimumPaymentTermFreelance, minimumPaymentTermSupplier } = responseProductData;
    if(minimumPaymentTermFreelance > 0 && minimumPaymentTermSupplier > 0) {
      if(data === 1) {
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? minimumPaymentTermFreelance
                  : minimumPaymentTermSupplier,
              "days"
          )
        )
      }
    }else {
      if(data === 1) {
        form.setFieldValue(
          "pay",
          moment(
              provider === 1 ?
                  startDate :
                  endDate
          ).add(
              provider === 1
                  ? 30
                  : 70,
              "days"
          )
        )
      }
    }
  };

  const onChangeProvider = (data: any) => {
    const { minimumPaymentTermFreelance, minimumPaymentTermSupplier } =
        responseProductData;
    if(form.getFieldValue("timing") === 1) {
      if( minimumPaymentTermFreelance > 0 && minimumPaymentTermSupplier > 0) {
        form.setFieldValue(
            "pay",
            moment(
                data === 1 ?
                  startDate :
                  endDate
            ).add(
                data === 1
                    ? minimumPaymentTermFreelance
                    : minimumPaymentTermSupplier,
                "days"
            )
        );
      }else {
        form.setFieldValue(
            "pay",
            moment(data === 1 ?
                startDate :
                endDate).add(
                data === 1
                    ? 30
                    : 70,
                "days"
            )
        );
      }
    }
    initialValues.providerType = data;
    setChosenProviderType(false);
    onChangeProviderType(data);
    setProvider(data);
    form.setFieldValue("unitMaximunPrice", "");
  };

  let initialValues = {
    typeRequest: project.typeRequest,
    category: "Compra & Contratação",
    allocation: "Com projeto", // refatorar
    requestLocation: project.buName,
    client: project.clientName,
    invoiceAgainst: "TV1",
    status: "Nova",
    purchaseType: "Projeto",
    providerType: "",
    timing: 1,
    emission: moment(), // new Date(), Formatar data
    startDate: moment(),
    endDate: moment(),
    pay: moment().add(30, "days"), //
    ExpenseOrInvestment: "",
    productType: "",
    quoteDeadline: "",
    SupplierPricing: "",
    unitMinimunPrice: "",
    unitMaximunPrice: "",
    productWithPrice: "",
    cotation: "",
    technicalScope: "",
    objectiveFunction: "",
    justifyIfItIsAnEmergency: "",
    JustifyReasonSingleSupplier: "",
    JustifyUnapprovedSupplier: "",
    JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate: "",
    JustifyTheDueDateBeingShorter: "",
    indicationSingleSupplierJustifiable: "",
  };

  useEffect(() => {
    if (pagination.data.current) requestForm();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
    sort,
    search,
    provider,
  ]);

  if (authRoles && !hasRole(authRoles?.create_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const props = {
    name: "file",
    multiple: true,
    action: "",
    customRequest: (file: any) => {
      setFilesItems([...filesItems, file.file]);
    },
    onDrop(e: any) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const removeFile = (file: any) => {
    const index = filesItems.findIndex((obj: any) => obj.uid === file.uid);
    const newArray = [...filesItems];
    if (index !== -1) {
      newArray.splice(index, 1);
      setFilesItems(newArray);
    }
  };

  const onFinish = async (values: any, btn: any = 2) => {
    const issueDateFormat = formatDate(values.emission);
    const serviceStartDateFormat = formatDate(values.startDate);
    const payFormat = formatDate(values.pay);
    const serviceEndDateFormat = formatDate(values.endDate);
    const negotiationDeadline = Number(form.getFieldValue("quoteDeadline"));
    const technicalScope = form.getFieldValue("technicalScope");
    const deliveryLocation =
      form.getFieldValue("objectiveFunction") ||
      form.getFieldValue("JustifyTheDueDateBeingShorter") ||
      form.getFieldValue(
        "JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate"
      ) ||
      form.getFieldValue("JustifyUnapprovedSupplier") ||
      form.getFieldValue("JustifyReasonSingleSupplier") ||
      form.getFieldValue("justifyIfItIsAnEmergency");
    const data = {
      issueDate: issueDateFormat,
      maturity: payFormat,
      typeProvider: suppliers[0]?.typeSupplier,
      category: values.category,
      allocation: values.allocation,
      isRequestNotTiedToProject: values.allocation === "Com projeto",
      providerType: values.providerType,
      paymentType: values.invoiceAgainst,
      senioridade: values.senioridade,
      unitMinimunPrice: values.unitMinimunPrice,
      unitMaximunPrice: values.unitMaximunPrice,
      suppliers: suppliers,
      technicalScope: values.technicalScope,
      /* o campo purchaseType agora será fixo com o valor projeto */
      purchaseType: "P",
      suppliersId: suppliers.map((item: { id: any }) => Number(item.id)),
      serviceStartDate: serviceStartDateFormat,
      serviceEndDate: serviceEndDateFormat,
      approvalHierarchyId: 12, // Alçada MVP
      negotiationDeadline,
      requireThreeQuotes: false,
      timing: values.timing === 1 ? "PLANEJADA" : "EMERGENCIAL",
      indicationSingleSupplierJustifiable:
        values.indicationSingleSupplierJustifiable !== "1",
      subsidiaryId: Number(project.subsidiaryId),
      items: productsLines.map((request: any) => ({
        productId: request.productId,
        costCenterId: request.costCenterId,
        buId: businessUnit[0],
        projectRevisionItemId: Number(request.id),
        amount: request.amount,
        daily: request.daily,
        unitCost: request.unitCost,
        unitSale: request.unitSale,
        totalSalesWithoutTax: request.amount * request.unitCost,
        deliveryLocation:
          request.JustifyTheDueDateBeingShorter ||
          request.JustifyUnapprovedSupplier ||
          request.JustifyReasonSingleSupplier ||
          request.justifyIfItIsAnEmergency ||
          request.objectiveFunction ||
          deliveryLocation,
        technicalScope,
      })),
      objectiveFunction: values.objectiveFunction,
      justifyIfItIsAnEmergency: values.justifyIfItIsAnEmergency,
      JustifyReasonSingleSupplier: values.JustifyReasonSingleSupplier,
      JustifyUnapprovedSupplier: values.JustifyUnapprovedSupplier,
      JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate:
        values.JustifyTheReasonWhyTheStartDateIsBeforeTheIssueDate,
      JustifyTheDueDateBeingShorter: values.JustifyTheDueDateBeingShorter,
      files: filesItems,
      btnStatus: btn,
    };
    if (data.suppliersId.length !== 0 || btn === 1) {
      switch (btnClick) {
        case "back":
          selectDataForm();
          prevStep();
          break;
        case "next":
          selectDataForm(data);
          nextStep();
          break;
        case "save":
          selectDataForm(data);
          break;
        default:
          selectDataForm();
          prevStep();
          break;
      }
    } else {
      message.error("Escolha um prestador");
    }
  };

  const prev = (e: any) => {
    const values = form.getFieldsValue();
    onFinish(values, 1);
    selectDataForm();
    prevStep();
  };

  const onAdd = async () => {
    const params = {
      productId: productsLinesSelected[0]?.productId,
      supplierId: suppliersId,
    };
    const productByPriceRange =
      await repositorySupplier.findProductPriceRangeByFilter(params);

    if (productByPriceRange?.preciary && provider === 2) {
      let productsLinesWithPriceRange = productsLines[0];
      productsLinesWithPriceRange.unitCost = productByPriceRange.value;
      setProductsLines([productsLinesWithPriceRange]);
    } else {
      let productsLinesWithPriceRange = productsLines[0];
      productsLinesWithPriceRange.unitCost = 0;
      setProductsLines([productsLinesWithPriceRange]);
    }
    const indexOf = mappedSuppliers.findIndex(
      (obj: { id: string }) => String(obj.id) === String(suppliersId)
    );
    const r = await repositorySupplier.getProductPriceRange(
      mappedSuppliers[indexOf]?.id
    );

    const filtered = r.filter(
      (product: any) => product.productId === productsLinesSelected[0].productId
    );

    let mapped = mappedSuppliers[indexOf];
    mapped.preciary = filtered[0]?.preciary;
    mapped.value = filtered[0]?.value ? filtered[0]?.value : "";
    mapped.unitOfMeasurement = filtered[0]?.unitOfMeasurement
      ? filtered[0]?.unitOfMeasurement
      : "";
    setProductByPriceRange(productByPriceRange);
    setSuppliers([mapped]);
    setOptionsSearchSuppliers([]);
    form.setFieldValue("SearchFieldSuppliers", "");

  };

  const onSearchSuppliers = async (searchText: string) => {
    const productIds = productsLinesSelected.map(
      (item: { productId: number }) => item.productId
    );

    const providerType = form.getFieldValue("providerType");
    const seniorityform = form.getFieldValue("senioridade");
    const queryParams = new URLSearchParams({
      corporateName: searchText,
      typeSupplier: providerType === 1 ? "FREELANCE" : "SUPPLIER",
      requestTiming: form.getFieldValue("timing") === 1 ? "true" : "false",
      page: "0",
      size: "10000",
    });
    productIds.forEach((id: any) => {
      queryParams.append("productIds", id.toString());
    });
    queryParams.append("seniority", seniorityform ? seniorityform : "");
    const response = await repositorySupplier.findSupplierByFilter(queryParams);
    if (!response) return;

    const mappedSuppliers = response.content.map((item) => ({
      value: item.corporateName,
      id: item.id,
    }));
    setOptionsSearchSuppliers(mappedSuppliers);
    setSuppliersSearch(response.content);
  };

  const onChangeSuppliers = (data: string) => {
    const indexOf = suppliersSearch.findIndex(
      (obj: { corporateName: string }) => obj.corporateName === data
    );
    const mappedSuppliers = suppliersSearch.map((request: any) => ({
      id: String(request.id),
      currentEvaluation:
        request.currentEvaluation !== null ? request.currentEvaluation : 0,
      currentAmountEvaluation: request.currentAmountEvaluation
        ? request.currentAmountEvaluation
        : 0,
      status: request.status,
      typeSupplier: request.typeSupplier,
      telephone: request.telephone,
      email: request.email,
      fantasyName: request.fantasyName,
      corporateName: request.corporateName,
    }));
    setMappedSuppliers([mappedSuppliers[indexOf]]);
    setSuppliersId(mappedSuppliers[indexOf].id);
  };

  const onChangeProviderType = (data: any) => {
    setSuppliers([]);
    handleProviderType(data);
  };

  const onChangeSenioridade = async (data: any) => {
    const params: any = {
      id: productsLinesSelected[0]?.productId,
    };
    setSeniority(data);
    const r = await repositoryProduct.findProductByFilter(params);
    let seniorityData = {} as any;
    switch (data) {
      case "JUNIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
            Number(r?.content[0]?.minimumPriceJunior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
            Number(r?.content[0]?.maximumPriceJunior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
          r?.content[0]?.minimumPriceJunior
        );
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPriceJunior
        );
        break;
      case "PLENO":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
            Number(r?.content[0]?.minimumPricePleno)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
            Number(r?.content[0]?.maximumPricePleno)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
          r?.content[0]?.minimumPricePleno
        );
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPricePleno
        );
        break;
      case "SENIOR":
        form.setFieldsValue({
          unitMinimunPrice: formatCurrency(
            Number(r?.content[0]?.minimumPriceSenior)
          ),
        });
        form.setFieldsValue({
          unitMaximunPrice: formatCurrency(
            Number(r?.content[0]?.maximumPriceSenior)
          ),
        });
        seniorityData.unitMinimunPrice = Number(
          r?.content[0]?.minimumPriceSenior
        );
        seniorityData.unitMaximunPrice = Number(
          r?.content[0]?.maximumPriceSenior
        );
        break;
    }
    setSeniorityData(seniorityData);
    setSuppliers([]);
  };

  const disabledDate = (current: any) => {
    const today = new Date(form.getFieldValue("emission"));
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };

  const disabledDateIssue = (current: any) => {
    const today = new Date(form.getFieldValue("endDate"));
    today.setHours(0, 0, 0, 0);
    return current && current < today;
  };
  const disabledSearchField = (provider: any, senioridade: any) => {
    if (provider === 2) {
      return false;
    } else if (provider === 1 && senioridade?.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Section>
        <div style={{ marginTop: 24 }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <>
              <Row>
                <Col xl={24}>
                  <b>Categorização, datas e gatilhos</b>
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField
                    name="typeRequest"
                    label="Tipo da requisição"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField
                    name="category"
                    label="Categoria da requisição"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField
                    name="allocation"
                    label="Alocação da requisição"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField
                    name="requestLocation"
                    label="Local da requisição"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField name="client" label="Cliente" disabled />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField
                    name="invoiceAgainst"
                    label="Fatura contra"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <TextField name="status" label="Status" disabled />
                </Col>
                <Col xl={6} xxl={3}>
                  <SelectField
                    name="timing"
                    label="Timing da requisição"
                    onChange={onChangetiming}
                    options={timingOptionsConvert}
                    rules={[
                      {
                        required: true,
                        message: "Selecione o Timing da requisição",
                      },
                    ]}
                    required
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <SelectField
                      name="providerType"
                      label="Tipo de Prestador"
                      options={providerTypeOptionsConvert}
                      onChange={onChangeProvider}
                      rules={[
                        {
                          required: true,
                          message: "Selecione o tipo de prestador",
                        },
                      ]}
                      required
                  />
                </Col>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.providerType !== currentValues.providerType
                    }
                >
                  {({ getFieldValue }) => (
                      <Col xl={6} xxl={3}>
                        <SelectField
                            name="senioridade"
                            label="Senioridade"
                            options={SenioridadeOptionsConvert}
                            onChange={onChangeSenioridade}
                            disabled={
                              getFieldValue("providerType") === 1 ? false : true
                            }
                        />
                      </Col>
                  )}
                </Form.Item>
                <Col xl={6} xxl={3}>
                  <TextField
                      name="ExpenseOrInvestment"
                      label="CAPEX/OPEX ?"
                      placeholder={"Despesa"}
                      disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <SelectField
                      name="productType"
                      label="Tipo do produto/serviço"
                      options={productTypeOptions}
                      disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <DateField name="emission" label="Emissão" disabled />
                </Col>
                <Col xl={6} xxl={3}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.timing !== currentValues.timing
                    }
                  >
                    {({ getFieldValue }) => (
                      <DateField
                        name="startDate"
                        label="Data do início do serviço"
                        disabledDate={
                          getFieldValue("timing") === 1
                            ? disabledDate
                            : () => {}
                        }
                        onChange={onChangeStartDate}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xl={6} xxl={3}>
                  <DateField
                    name="endDate"
                    label="Data do fim do serviço"
                    onChange={onChangeEndDate}
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.timing !== currentValues.timing
                    }
                  >
                    {({ getFieldValue }) => (
                      <DateField
                        name="pay"
                        label="Vencimento [Política]"
                        onChange={onChangeIssueDate}
                        disabled={getFieldValue("timing") === 1}
                      />
                    )}
                  </Form.Item>
                </Col>
                {/* <Col xl={6} xxl={3}>
                  <TextField
                    name="unitMinimunPrice"
                    label="Preço mínimo [Freela]"
                    disabled
                  />
                </Col> */}
                <Col xl={6} xxl={3}>
                  <TextField
                    name="unitMaximunPrice"
                    label="Preço Máximo [Freela]"
                    disabled
                  />
                </Col>
                {/* <Col xl={6} xxl={3}>
                  <TextField
                    name="SupplierPricing"
                    label="Negociado com Fornecedor único"
                    disabled
                  />
                </Col> */}
              </Row>

              <Row>
                <Col xl={6} xxl={3}>
                  <CheckField
                    name="productWithPrice"
                    label="Produto com preciário"
                    disabled
                  />
                </Col>
                <Col xl={6} xxl={3}>
                  <CheckField
                    name="cotation"
                    label="Cotação obrigatória"
                    disabled={true}
                  />
                </Col>
                <Col xl={18}>
                  <Radio.Group
                    onChange={onChangeIndicationSupplier}
                    value={unico}
                  >
                    {/*<Radio value={1}>*/}
                    {/*  Prefiro que Compras escolha os prestadores*/}
                    {/*</Radio>*/}
                    <Radio value={2} disabled>
                      Prestador único indicado pelo requisitante ou comprador
                    </Radio>
                    <Radio value={3} disabled>
                      Fornecedor único indicado pelo Cliente
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider />
                </Col>
                <Col xl={24}>
                  <b>Fornecedores ou freelancers para essa requisição</b>
                </Col>
                <Col xl={12} xxl={12}>
                  <div style={{ display: "flex", width: "100%" }}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.providerType !==
                          currentValues.providerType ||
                        prevValues.senioridade !== currentValues.senioridade
                      }
                    >
                      {({ getFieldValue }) => (
                        <SearchField
                          onSearch={onSearchSuppliers}
                          onChange={onChangeSuppliers}
                          required
                          name="SearchFieldSuppliers"
                          placeholder="Digite aqui o nome"
                          options={optionsSearchSuppliers}
                          disabled={disabledSearchField(
                            getFieldValue("providerType"),
                            getFieldValue("senioridade")
                          )}
                          value={""}
                        />
                        // <Col xl={24}>
                        //   <AreaField
                        //     name="justifyIfItIsAnEmergency"
                        //     label="Justifique a escolha se requisição for EMERGENCIAL"
                        //     onChange={(event) =>
                        //       (initialValues.justifyIfItIsAnEmergency =
                        //         event.target.value)
                        //     }
                        //     rules={[
                        //       {
                        //         required: getFieldValue("timing") === 2,
                        //         message: "justificativa obrigatória",
                        //       },
                        //     ]}
                        //     required={getFieldValue("timing") === 2}
                        //     disabled={
                        //       getFieldValue("timing") === 2 ? false : true
                        //     }
                        //   />
                        // </Col>
                      )}
                    </Form.Item>
                    <Button type="primary" onClick={onAdd}>
                      Adicionar
                    </Button>
                  </div>
                </Col>
                <Col>
                  <Table
                    columns={
                      provider === 1 ? columnsFreelancer : columnsSuppliers
                    }
                    dataSource={suppliers}
                    pagination={false}
                  />
                </Col>
                <Col>
                  <div style={{ marginBottom: "40px" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Linhas e produtos escolhidos para a requisição</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <Table
                    columns={columnsLines}
                    dataSource={productsLinesNotEdited}
                    pagination={false}
                  />
                </Col>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Edite abaixo a quantidade, diarias e o valor dessa requisição</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <Table
                      columns={columnsLinesEditable}
                      dataSource={productsLines}
                      pagination={false}

                  />
                </Col>
                <Col>
                  <div style={{ marginBottom: "40px" }}></div>
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Escopo técnico detalhado e anexos</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <AreaField
                    required
                      name="technicalScope"
                      maxLength={-1}
                      label="Escopo técnico detalhado"
                      rules={[
                        {
                          required: true,
                          message: "Escopo técnico obrigatório",
                        },
                      ]}
                  />
                </Col>
                <Col xl={24}>
                  <UploadWrapper>
                    <Dragger {...props} showUploadList={false}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloudUploadOutlined
                          style={{ fontSize: "24px", marginTop: "-5px" }}
                        />
                        <p className="ant-upload-text">
                          Clique ou arraste para realizar o upload
                        </p>
                      </div>
                    </Dragger>
                  </UploadWrapper>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div></div>
                </Col>
                <Col xl={24}>
                  <Table
                    pagination={false}
                    dataSource={filesItems}
                    columns={[
                      {
                        title: "Nome do arquivo",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Formato",
                        dataIndex: "type",
                        key: "type",
                      },
                      {
                        title: "",
                        dataIndex: "delete",
                        key: "delete",
                        render(_: any, record: any) {
                          return (
                            <span
                              onClick={() => removeFile(record)}
                              style={{ cursor: "pointer" }}
                            >
                              excluir
                            </span>
                          );
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider />
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <div style={{ marginTop: "20px" }}>
                    <b>Questões complementares para a alçada de aprovação</b>
                  </div>
                </Col>
                <Col xl={24}>
                  <AreaField
                    name="objectiveFunction"
                    maxLength={-1}
                    label="Qual o objetivo da contratação? Temos essa função dentro de casa? Avaliamos a disponibilidade?"
                    onChange={(event) =>
                      (initialValues.objectiveFunction = event.target.value)
                    }
                    rules={[
                      {
                        required: true,
                        message: "justificativa obrigatória",
                      },
                    ]}
                    required
                  />
                </Col>
              </Row>
              {/* <Button style={{ margin: "0 4px" }} onClick={() => prev()}>
                Voltar
              </Button> */}
              <Button style={{ margin: "0 4px" }} onClick={prev}>
                Voltar
              </Button>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.providerType !== currentValues.providerType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("providerType") === 1 ||
                  getFieldValue("providerType") === "" ? (
                    <Button
                      style={{ margin: "0 4px" }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => setBtnClick("next")}
                    >
                      Próximo
                    </Button>
                  ) : (
                    <Button
                      style={{ margin: "0 4px" }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setBtnClick("save")}
                    >
                      Salvar
                    </Button>
                  )
                }
              </Form.Item>
            </>
          </Form>
        </div>
      </Section>
      <Modal
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default FormRequest;
