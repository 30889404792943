import React, { useEffect, useMemo, useState } from "react";
import type { TableProps } from "antd/lib/table";
import { Navigate, useNavigate } from "react-router";
import {Button, Collapse, Dropdown, message, Modal, Popover, Table, Tag} from "antd";
import { DropdownButton, PageHeader, Section } from "components";
import { TClient, useRequest, useSuppliers } from "repositories";
import { useAuth, useFilters, usePagination } from "hooks";
import { SorterResult } from "antd/lib/table/interface";
import { Drp, FilterContainer } from "./styles";
import { DownOutlined } from "@ant-design/icons";
import {
  formatDateToView,
  formatCurrency,
  formatDateForRequest,
  formatDateToServer,
  removeMaskCnpj,
} from "utils/helpers";
import FiltersForm from "./components/FiltersForm";
import useCostCenter from "../../../repositories/useCostCenter";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import moment from "moment";
import RequestCancelForm from "./components/RequestCancelForm/RequestCancelForm";
import RequestSuspendForm from "./components/RequestSuspendForm/RequestSuspendForm";
const { confirm } = Modal
type TClientData = Pick<TClient, "id" | "cnpj" | "corporateName" | "status"> & {
  key: string;
};

const Requests = () => {
  const [clients, setClients] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("id,DESC");
  const filters = useFilters();
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
  const [suppliers, setSuppliers] = useState<any>([]);
  const [costCenter, setCostCenter] = useState<any>([]);
  const [cancelRequestStatus, setCancelRequestStatus] = useState<any>({});
  const { roles: authRoles, hasRole } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalProjectsOpen, setIsModalProjectsOpen] = useState(false)
  const [isModalClientsOpen, setIsModalClientsOpen] = useState(false)

  const [currentRequestModal, setCurrentRequestModal] = useState<any>([])
  const [currentProjectsModal, setCurrentProjectsModal] = useState<any>([])
  const [isVisibleRequestCancelForm, setIsVisibleRequestCancelForm] = useState<any>(false)
  const [isVisibleRequestSuspendForm, setIsVisibleRequestSuspendForm] = useState<any>(false)
  const [rechargCancel, setRechargCancel] = useState<any>(0)
  const [rechargSuspend, setRechargSuspend] = useState<any>(0)
  const [requestCancelId, setRequestCancelId] = useState<any>()
  const [requestSuspendId, setRequestSuspendId] = useState<any>()
  const [currentClientsModal, setCurrentClientsModal] = useState<any>([])

  const [columnsInstallment, setColumnsInstallment] = useState<any>([]);

  const repository = useRequest();
  const supplierRepository = useSuppliers();
  const costCenterRepository = useCostCenter();
  const navigate = useNavigate();
  const pagination = usePagination();

  const openClientsModal = (record: any) => {

    const uniqueArray = record?.items?.filter((item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t?.projectRevision?.project?.clientIdErp === item?.projectRevision?.project?.clientIdErp
      ))
    );

    setCurrentClientsModal(uniqueArray)
    setIsModalClientsOpen(true)

  }

  const openProjectsModal = (record: any) => {

    const uniqueArray = record?.items?.filter((item: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t?.projectRevision?.project?.idERP === item?.projectRevision?.project?.idERP
      ))
    );

    setCurrentProjectsModal(uniqueArray)
    setIsModalProjectsOpen(true)

  }

  const columnsApportionmentWithInstallment = [
    {
      title: "Id Projeto",
      dataIndex: "projectIdERP",
    },
    {
      title: "Projeto",
      dataIndex: "projectName",
      onHeaderCell: () => ({
        style: { minWidth: 220 },
      }),
    },
    {
      title: "Linha",
      dataIndex: "lineIdErp",
      onHeaderCell: () => ({
        style: { minWidth: 220 },
      }),
      render: (_: any, record: any) => (
        <Popover content={record?.lineIdErp} title="Linha - Descrição">
          {`${record?.lineIdErp?.substring(0, 22)}...`}
        </Popover>
      ),
    },
    {
      title: "Percentual Rateio (%)",
      dataIndex: "percentage",
      render: (_: any, record: any) => {
        return `${record.percentage} %`;
      },
    },
  ]

  const openRequests = (record: any) => {

    const columnsInstallment: any = []

    const sortedItems = {
      ...record,
      items: record?.items?.map((item: any) => ({
        ...item,
        apportions: item?.apportions?.sort((a: any, b: any) => {
          const dateA: any = new Date(a.dueDate);
          const dateB: any = new Date(b.dueDate);
          return dateA - dateB;
        })
      }))
    }

    sortedItems?.items[0]?.apportions?.forEach((apportion: any, index: any) => {

      let totalValue = 0;

      sortedItems?.items?.forEach((item: any) => {
          item.apportions.forEach((apport: any) => {
              if (apport.dueDate === apportion.dueDate) {
                  totalValue += apport.value;
              }
          });
      });

      columnsInstallment.push({
        title: `${index + 1}° Parcela \n ${formatDateToView(apportion.dueDate)} ${formatCurrency(totalValue)}`,
        dataIndex: `${index + 1}installment`,
        render: (apportion: any, record: any) => {
          return <span>{formatCurrency(record?.apportions[index]?.value)}<br />{record?.apportions[index]?.idErpCorrelation}</span>
        }
      });
    });


    const updatedColumns = [
      ...columnsApportionmentWithInstallment,
      ...columnsInstallment,
      {
        title: `Total ${formatCurrency(sortedItems?.items?.reduce((sum: any, item: any) => sum + item.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0), 0))}`,
        dataIndex: "totalValue",
        render: (_: any, record: any) => {
          return <span>{formatCurrency(record?.apportions?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.value, 0))}</span>
        }
      },
    ]

    setColumnsInstallment(updatedColumns)

    let mappedRecord: any = []

    sortedItems?.items?.forEach((item: any) => {

      mappedRecord = [...mappedRecord, {
        projectIdERP: item?.projectIdErp,
        projectName: item?.projectName,
        lineIdErp: `${item?.projectRevisionItem?.lineIdErp} - ${item?.projectRevisionItem?.description}`,
        percentage: item?.percentage,
        apportions: item.apportions
      }]

    });
    setCurrentRequestModal(mappedRecord)
    setIsModalOpen(true)
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "ID TOTVS",
      dataIndex: "idErp",
      key: "idErp",
      sorter: true,
      render: (_: any, record: any) => {
        if(record?.typeRequest === 'COMPOSED'){
          return (
            <Tag
              color={record?.status === 'APPROVED' ? 'green' : 'default'}
              style={{ cursor: "pointer" }}
              onClick={() => openRequests(record)}
            >
              Múltiplos IDs
            </Tag>
          )
        }else {
          return record?.idErp
        }
        
      },
    },
    {
      title: "Emissão",
      dataIndex: "issueDate",
      key: "issueDate",
      sorter: true,
      render: (_: any, record: any) => {
        return formatDateForRequest(record?.issueDate);
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      key: "maturity",
      sorter: true,
      render: (_: any, record: any) => {
        if(record?.typeRequest === 'COMPOSED'){
          return (
            <Tag
              color={record?.status === 'APPROVED' ? 'green' : 'default'}
              style={{ cursor: "pointer" }}
              onClick={() => openRequests(record)}
            >
              Múltiplos Venc.
            </Tag>
          )
        }else {
          return formatDateForRequest(record?.maturity);
        }
      },
    },
    {
      title: "Produto",
      dataIndex: "productDescription",
      key: "productDescription",
      sorter: false,
    },
    //{
    // title: "Prestador",
    //dataIndex: "Prestador",
    //  key: "Prestador",
    //  sorter: true,
    // },
    //{
    //  title: "Tipo",
    //   dataIndex: "typeRequest",
    //   key: "typeRequest",
    //  render: (_: any, record: any) => {
    //     if (record.typeRequest === "SIMPLE") return "Simples";
    //    return "Composta";
    //   },
    //   sorter: true,
    // },
    //{
    //   title: "Categoria",
    //   dataIndex: "Categoria",
    //  key: "Categoria",
    //   sorter: true,
    // },
    {
      title: "Alocação",
      dataIndex: "projectExist",
      key: "projectExist",
      sorter: false,
      render: (_: any, record: any) => {
        if(record?.typeRequest === 'COMPOSED'){

          if(record?.items?.every((item: any) => item?.projectRevision?.project?.idERP === record?.items[0]?.projectRevision?.project?.idERP)) {
            return record?.projectExist;
          }else {
            return (
              <Tag
                color={record?.status === 'APPROVED' ? 'green' : 'default'}
                style={{ cursor: "pointer" }}
                onClick={() => openProjectsModal(record)}
              >
                Múltiplos Projetos
              </Tag>
            )
          }

        }else {
          return record?.projectExist;
        }
      },
    },
    {
      title: "Local",
      dataIndex: "buName",
      key: "buName",
      sorter: false,
    },
    {
      title: "Cliente/CC",
      dataIndex: "clientName",
      key: "clientName",
      sorter: false,
      render: (_: any, record: any) => {
        if(record?.typeRequest === 'COMPOSED'){

          if(record?.items?.every((item: any) => item?.projectRevision?.project?.clientIdErp === record?.items[0]?.projectRevision?.project?.clientIdErp)) {
            return record?.clientName;
          }else {
            return (
              <Tag
                color={record?.status === 'APPROVED' ? 'green' : 'default'}  
                style={{ cursor: "pointer" }}
                onClick={() => openClientsModal(record)}
              >
                Múltiplos Clientes
              </Tag>
            )
          }

        }else {
          return record?.clientName;
        }
      },
    },
    {
      title: "Valor",
      dataIndex: "totalCost",
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if(record?.typeRequest === 'COMPOSED'){
          return (
            <Tag color="red">
              Rateio
            </Tag>
          )
        }else {
          return (
            <Tag color="yellow">
              Simples
            </Tag>
          )
        }
      },
    },
    {
      title: "Timing",
      dataIndex: "approvalHierarchyTiming",
      key: "approvalHierarchyTiming",
      sorter: false,
      render: (_: any, record: any) => {
        if (record.approvalHierarchyTiming === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;
  
        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: false,
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            // return "Recusado";
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "SUSPEND":
            return <Tag color="magenta">Suspensa</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
    // {
    //   title: 'Cnpj',
    //   dataIndex: 'cnpj',
    //   key: 'cnpj',
    //   sorter: true
    // },
    // {
    //   title: 'Razão Social',
    //   dataIndex: 'corporateName',
    //   key: 'corporateName',
    //   sorter: true
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   sorter: true,
    //   render: (_, record) => {
    //     if (record.status) return <Tag color='green'>Ativo</Tag>
  
    //     return <Tag color='red'>Inativo</Tag>
    //   },
    // }
  ];

  enum SupplierType {
    SUPPLIER = "FORNECEDOR",
    FREELANCE = "FREELANCE",
  }

  const requestsColumns: any = [
    {
      title: "Valor",
      dataIndex: "value",
      render: (_: any, record: any) => {
        return formatCurrency(record?.value)
      }
    },

    {
      title: "Vencimento",
      dataIndex: "maturityApportion",
      render: (_: any, record: any) => {
        return record?.maturityApportion
          ? (<span>{moment(record?.maturityApportion).format("DD/MM/YYYY")}<br></br>{record?.idErpCorrelation}</span>)
          : "";
      }
    }
  ];

  const projectsColumns: any = [
    {
      title: "ID ERP",
      dataIndex: "idERP",
      render: (_: any, record: any) => {
        return record?.projectRevision?.project?.idERP
      }
    },
    {
      title: "Nome",
      dataIndex: "name",
      render: (_: any, record: any) => {
        return record?.projectRevision?.project?.name
      }
    }
  ];

  const clientsColumns: any = [
    {
      title: "ID ERP",
      dataIndex: "idERP",
      render: (_: any, record: any) => {
        return record?.projectRevision?.project?.clientIdErp
      }
    },
    {
      title: "Nome",
      dataIndex: "name",
      render: (_: any, record: any) => {
        return record?.projectRevision?.project?.clientName
      }
    },
    {
      title: "CNPJ",
      dataIndex: "name",
      render: (_: any, record: any) => {
        return record?.projectRevision?.project?.clientCnpj
      }
    }
  ];

  const supplierTypeLabel = (type: string) => {
    switch (type) {
      case SupplierType.SUPPLIER:
        return "Fornecedor";
      case SupplierType.FREELANCE:
        return "Freelancer";
      default:
        return "";
    }
  };
  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true);
  };
  const formatFilter = (_filters: any) => {
    if (_filters.startIssueDate) {
      _filters.startIssueDate = formatDateToServer(_filters.startIssueDate);
    }
    if (_filters.endIssueDate) {
      _filters.endIssueDate = formatDateToServer(_filters.endIssueDate);
    }
    if (_filters.startIssueDate) {
      _filters.startIssueDate = formatDateToServer(_filters.startIssueDate);
    }
    if (_filters.startMaturity) {
      _filters.startMaturity = formatDateToServer(_filters.startMaturity);
    }
    if (_filters.endMaturity) {
      _filters.endMaturity = formatDateToServer(_filters.endMaturity);
    }
    if (_filters.supplierIdCnpj) {
      for (const objeto of suppliers) {
        if (
          removeMaskCnpj(objeto.cnpj) ===
          removeMaskCnpj(_filters.supplierIdCnpj)
        ) {
          _filters.supplierIdCnpj = objeto.value;
        }
      }
    }
    if (_filters.costCenterId) {
      for (const objeto of costCenter) {
        if (objeto.description === removeMaskCnpj(_filters.costCenterId)) {
          _filters.costCenterId = objeto.value;
        }
      }
    }

    return _filters;
  };
  const requestClient = async () => {
    const responseSupplier = await supplierRepository.findSupplierByFilter({
      page: 0,
      size: 10000,
    });
    if (!responseSupplier) return;

    const mappedSupplier = responseSupplier.content.map((item: any) => ({
      value: item.id,
      cnpj: item.cnpj,
    }));

    if (!responseSupplier) return;
    setSuppliers(mappedSupplier);

    const responseCostCenter =
      await costCenterRepository.findByFilterCostCenter({
        page: 0,
        size: 10000,
      });
    if (!responseCostCenter) return;

    const mappedCostCenter = responseCostCenter.content.map((item: any) => ({
      value: item.id,
      description: item.description,
    }));

    if (!responseCostCenter) return;
    setCostCenter(mappedCostCenter);

    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();
    const formatedFilters = formatFilter(_filters);

    const params: any = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort,
      ...formatedFilters,
    };
    const response = await repository.findRequests(params);
    if (!response) return;

    if (response.content.length === 0)
      message.warn("Você não possui requisições no momento");

    // Necessária refatoração para comtemplar todos os itens vindos da requisição

    const mappedData = response.content.map((request: any) => ({
      id: String(request.id),
      idErp: request.idErp,
      issueDate: request.issueDate,
      maturity: request.maturity,
      totalCost: request?.typeRequest === 'COMPOSED' ? formatCurrency(request?.items?.reduce((soma: any, valorAtual: any) => soma + valorAtual?.totalCost, 0)) : formatCurrency(request?.items[0]?.totalCost),
      productDescription: request.items[0].productDescription,
      Prestador: supplierTypeLabel(request.typeProvider),
      typeRequest: request?.typeRequest,
      items: request?.purchaseOrder?.items,
      approvalHierarchyTiming: request.approvalHierarchyTiming,
      // Categoria ?
      Categoria: "Compra & Contratação",
      projectExist: request.items[0].projectRevision?.project.idERP ? request.items[0].projectRevision?.project.idERP : "Sem projeto",
      buName: request.items[0].projectRevision?.project.buName,
      clientName: request.items[0].projectRevision?.project.clientName,
      status: request.statusRequest,
    }));
    setClients(mappedData);
    pagination.changeTotalPages(response.totalPages);
  };

  const handleChange: TableProps<TClientData>["onChange"] = (
    paginationConfig,
    filters,
    sorter
  ) => {
    pagination.onChangeTable(paginationConfig);
    const { field, order } = sorter as SorterResult<TClientData>;
    if (!field) return;

    const dir = order === "ascend" ? "ASC" : "DESC";
    setSort(`${field},${dir}`);
  };

  useEffect(() => {
    if (pagination.data.current) requestClient();
  }, [
    pagination.data.current,
    pagination.data.pageSize,
    sort,
    search,
    filters.items,
    cancelRequestStatus,
    rechargSuspend,
    rechargCancel,
  ]);

  const onClickGoToView = (id: number) => () => window.open(`/requisicao/visualizar/${id}`);

  const onClickCancelRequest = (id: any) => () => {
    setRequestCancelId(id)
    setIsVisibleRequestCancelForm(true);
  };

  const onClickSuspendRequest = (id: any) => () => {
    setRequestSuspendId(id)
    setIsVisibleRequestSuspendForm(true);
  };

  const updatedColumns = useMemo(
    () => [
      ...columns,
      {
        title: "",
        key: "action",
        render: (_: any, record: any) => {
          const id = Number(record.id);
          const itemsMenu = [
            {
              key: "1",
              label: "Visualizar",
              onClick: onClickGoToView(id),
              disabled: false,
            },
            {
              key: "2",
              label: "Cancelar",
              onClick: onClickCancelRequest(id),
              disabled: ((record.status === "NEW" || record.status === "ON_APPROVAL") && record?.typeRequest === 'SIMPLE') ? false : true,
            },
            {
              key: "3",
              label: "Clonar",
              disabled: true,
            },
            {
              key: "4",
              label: "Contrato",
              disabled: true,
            },
            {
              key: "5",
              label: "Suspender",
              onClick: onClickSuspendRequest(id),
              disabled: ((record.status === "NEW" || record.status === "ON_APPROVAL") && record?.typeRequest === 'COMPOSED') ? true : true,
            },
          ];
          return <DropdownButton items={itemsMenu} />;
        },
      },
      // {
      //   title: '',
      //   key: 'action',
      //   render: (_, record) => (
      //     <Button
      //       onClick={onClickGoToView(record.id)}
      //       disabled={!hasRole(authRoles?.client_view)}
      //       size='small'
      //       type='text'
      //     >
      //       Visualizar
      //     </Button>
      //   ),
      // }
    ],
    [authRoles]
  );

  const onSearch = (value: string) => setSearch(value);

  if (authRoles && !hasRole(authRoles?.view_request_simple)) {
    return <Navigate to="/acesso-negado" />;
  }

  const { Panel } = Collapse;
  const resetPagination = () => {
    pagination.onChange(1, pagination.data.pageSize ?? 10);
  };

  const ItemsMenuSimples = () => (
    <Drp>
      <Collapse>
        <Panel header="Compra & Contratação" key="1">
          <Button href="/compra-contratacao/atrelada-projeto/nova">
            Com Projeto
          </Button>
        </Panel>
      </Collapse>
    </Drp>
  );
  const ItemsMenuComposite = () => (
      <Drp>
        <Collapse>
          <Panel header="Rateios e Contratos" key="1">
            <Button href="/rateio-contratos/atrelada-projeto/nova">
              Com Projeto
            </Button>
          </Panel>
        </Collapse>
      </Drp>
  );

  return (
    <>
      <PageHeader
        title="Requisições"
        breadcrumbItems={["Requisições", "Listar"]}
      />
      <Section>
        <FilterContainer>
          <Button onClick={onClickOpenFilterForm}>Filtros</Button>

          <Dropdown
            dropdownRender={(menu) => <ItemsMenuSimples />}
            placement="bottomLeft"
            trigger={["click"]}
            disabled={!hasRole(authRoles?.create_request_simple)}
          >
            <Button type="primary">
            Criar requisição simples<DownOutlined />
            </Button>
          </Dropdown>

          <Dropdown
            dropdownRender={(menu) => <ItemsMenuComposite />}
            placement="bottomLeft"
            trigger={["click"]}
            // disabled
          >
            <Button type="primary">
              Criar requisição composta <DownOutlined />
            </Button>
          </Dropdown>
        </FilterContainer>
        <Table
          size="middle"
          onChange={handleChange}
          loading={repository.loading}
          columns={updatedColumns}
          dataSource={clients}
          pagination={pagination.data}
        />
      </Section>
      <FiltersForm
        resetPagination={resetPagination}
        filters={filters}
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
      />

      <Modal
        title="Pedidos gerados"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={columnsInstallment}
          dataSource={currentRequestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>

      <Modal
        title="Projetos"
        className="horizontal-scrollable-modal"
        open={isModalProjectsOpen}
        onOk={() => setIsModalProjectsOpen(false)}
        onCancel={() => setIsModalProjectsOpen(false)}
        width={1300}
      >
        <Table
          columns={projectsColumns}
          dataSource={currentProjectsModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
      <RequestCancelForm
        id={requestCancelId}
        isVisible={isVisibleRequestCancelForm}
        setIsVisible={setIsVisibleRequestCancelForm}
        setRecharg={setRechargCancel}
      />
      <RequestSuspendForm
        id={requestSuspendId}
        isVisible={isVisibleRequestSuspendForm}
        setIsVisible={setIsVisibleRequestSuspendForm}
        setRecharg={setRechargSuspend}
      />
      <Modal
        title="Clientes"
        className="horizontal-scrollable-modal"
        open={isModalClientsOpen}
        onOk={() => setIsModalClientsOpen(false)}
        onCancel={() => setIsModalClientsOpen(false)}
        width={1300}
      >
        <Table
          columns={clientsColumns}
          dataSource={currentClientsModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </Modal>
    </>
  );
};

export default Requests;
