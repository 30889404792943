import React, { useEffect, useMemo, useState } from "react";
import {
  TAddressRequest,
  TBank,
  TBankAccountRequest,
  TSupplierForm,
  TSupplierRequest,
  useBanks,
  useCommon,
  useSuppliers,
} from "repositories";
import {
  Section,
  PageHeader,
  TextField,
  Row,
  Col,
  HiddenField,
  SelectField,
  SearchField,
  CheckField,
  MaskedField,
} from "components";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import { Button, Divider, Form, message, Radio, Space } from "antd";
import {
  analysisSupplierOptions,
  MaskPhone, nationalityType,
  seniorityOptions,
  StatusApproveSupplier, supplierCreationStatusOptions,
  SupplierStatus,
  supplierStatusOptions,
  SupplierType,
  TaxPayer,
  taxPayerOptions,
  YesNo,
  yesNoOptions,
} from "utils/constants";
import { useAuth } from "hooks";
import { Rule } from "antd/lib/form";
import { validateCnpjAndRequired } from "utils/helpers";
import RelatedProducts from "../Components/RelatedProducts";
import ContactList from "../Components/ContactList";
import Attachments from "../Components/Attachments";
import {DefaultOptionType} from "antd/lib/select";
import countries from "../../../utils/countries.json"
import {values} from "lodash";

const initialValues: any = {
  id: "",
  cnpj: "",
  corporateName: "",
  fantasyName: "",
  typeCompany: "",
  principal: "",
  email: "",
  financialPrincipal: "",
  financialPrincipalEmail: "",
  telephone: "",
  fiscalAnalysis: StatusApproveSupplier.NOT_INFORMED,
  financialAnalysis: StatusApproveSupplier.NOT_INFORMED,
  status: SupplierStatus.PRE_REGISTRATION,
  typeSupplier: SupplierType.SUPPLIER,
  foreigner: false,
  taxpayer: "",
  simpleNational: YesNo.NO,
  municipalInscription: "",
  stateInscription: "",
  categories: [],
  products: [],
  isForeign: false,
  zipCode: "",
  publicPlace: "",
  number: "",
  complement: "",
  neighborhood: "",
  state: "",
  city: "",
  country: "",
  termsAcceptance: false,
  seniority: "",
};

const optionsForeigner = [
  {
    value: "ESTRANGEIRA",
    label: "Estrangeira",
  },
]

const optionsNational = [
    {
      value: "MEI",
      label: "MEI",
    },
    {
      value: "EIRELI",
      label: "EIRELI",
    },
    {
      value: "EMPRESARIO_INDIVIDUAL",
      label: "Empresário Individual",
    },
    {
      value: "SOCIEDADE_EMPRESARIA_LIMITADA",
      label: "Sociedade Empresária Limitada",
    },
    {
      value: "SOCIEDADE_SIMPLES",
      label: "Sociedade Simples",
    },
    {
      value: "SOCIEDADE_ANONIMA",
      label: "Sociedade Anônima",
    },
    {
      value: "SOCIEDADE_LIMITADA_UNIPESSOAL",
      label: "Sociedade Limitada Unipessoal",
    },
]

const validations: Record<string, Rule[]> = {
  cnpj: [
    () => ({
      validator(_, cnpj: string) {
        return validateCnpjAndRequired(cnpj);
      },
    }),
  ],
  telephone: [{ required: true, message: 'Campo "Telefone" é obrigatório' }],
  corporateName: [
    { required: true, message: 'Campo "Razão Social" é obrigatório' },
  ],
  email: [
    { required: true, message: 'Campo "Email" é obrigatório' },
    { type: "email", message: "Email está inválido" },
  ],
  principal: [
    { required: true, message: 'Campo "Nome do responsável" é obrigatório' },
  ],
  financialPrincipalEmail: [{ type: "email", message: "Email está inválido" }],
  taxpayer: [
    { required: true, message: 'Campo "Tipo de inscrição" é obrigatório' },
  ],
  simpleNational: [
    {
      required: true,
      message: 'Campo "Optante Simples Nacional" é obrigatório',
    },
  ],
  stateInscription: [
    ({ getFieldValue }) => ({
      validator(_, stateInscription: string) {
        const taxPayer = getFieldValue("taxpayer");
        if (taxPayer === TaxPayer.CONTRIBUTOR && !stateInscription) {
          return Promise.reject(
            new Error('Campo "Inscrição Estadual" é obrigatório"')
          );
        }

        return Promise.resolve();
      },
    }),
  ],
  municipalInscription: [
    { required: true, message: 'Campo "Inscrição Municípal" é obrigatório' },
  ],
  fantasyName: [
    { required: true, message: 'Campo "Nome fantasia" é obrigatório para fornecedor estrangeiro' },
  ],
  bankName: [{ required: true, message: 'Campo "Banco" é obrigatório' }],
  legalPersonAccount: [
    { required: true, message: 'Campo "Tipo de Conta" é obrigatório' },
  ],
  bankAgencyDigit: [
    {
      required: true,
      message: 'Campo "N° Agência" é obrigatório. Caso não possua, digite 0',
    },
  ],
  bankAccount: [{ required: true, message: 'Campo "N° Conta" é obrigatório' }],
  bankAgency: [{ required: true, message: 'Campo "N° Agência" é obrigatório' }],
  bankAccountDigit: [
    { required: true, message: 'Campo "N° Dígito" é obrigatório' },
  ],
  bankAccountFirstDigit: [
    { required: true, message: "Se a conta não possuir dígito coloque 0" },
  ],
  zipCode: [
    ({ getFieldValue }) => ({
      validator(_, zipCode: string) {
        const isForeign = getFieldValue("isForeign");
        if (isForeign && !zipCode) {
          return Promise.reject(new Error('Campo "Zip Code" é obrigatório"'));
        }

        if (!isForeign && !zipCode) {
          return Promise.reject(new Error('Campo "CEP" é obrigatório"'));
        }

        return Promise.resolve();
      },
    }),
  ],
  publicPlace: [
    { required: true, message: 'Campo "Logradouro" é obrigatório' },
  ],
  neighborhood: [{ required: true, message: 'Campo "Bairro" é obrigatório' }],
  city: [{ required: true, message: 'Campo "Cidade" é obrigatório' }],
  state: [{ required: true, message: 'Campo "Estado" é obrigatório' }],
  country: [{ required: true, message: 'Campo "Páis" é obrigatório' }],
};

const messageAppendEmailField =
  "Email será usado para enviar o link, onde o fornecedor irá finalizar o cadastro.";

export type TOptions = {
  idPais: string;
  descricao: string;
};

const convertToOptions = (items: TOptions[]) =>
    items.map(
        (item) =>
            ({
              label: item.descricao,
              value: item.idPais,
            } as any)
    );

const foreignerOptions = convertToOptions(countries);

const SupplierForm = () => {
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState<TBank[]>([]);
  const [typeCompany, setTypeCompany] = useState("");
  const [selectForeigner, setSelectForeigner] = useState("");
  const [foreignerOptionsConvert, setForeignerOptionsConvert] = useState<any>(foreignerOptions);
  const [typeSupplier, setTypeSupplier] = useState();
  const [foreigner, setForeigner] = useState<any>(false);
  const [country, setCountries] = useState("");
  const [isDisabledSeniority, setIsDisabledSeniority] = useState(false);
  const [supplier, setSupplier] = useState<TSupplierForm>({} as TSupplierForm);
  const [bankOptions, setBankOptions] = useState<{ value: string }[]>([]);
  const [bankAgency, setBankAgency] = useState('')

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { hasRole, roles: authRoles } = useAuth();
  const { id } = useParams<{ id: string }>();
  const repository = useSuppliers();
  const bankRepository = useBanks();
  const repositoryCommon = useCommon();
  const [form] = Form.useForm<TSupplierForm>();
  const telephoneWatch = Form.useWatch("telephone", form);
  const statusWatch = Form.useWatch("status", form);
  const watchedForeign = Form.useWatch("isForeign", form);
  const watchedTaxPayer = Form.useWatch("taxpayer", form);

  useEffect(() => {
    const getSupplier = async () => {
      if (!id) return;
      const response = await repository.findSupplierById(Number(id));
      if (!response) return;
      const data: any = {
        id: response.id,
        TAXID: response.taxId,
        cnpj: response.cnpj,
        corporateName: response.corporateName,
        fantasyName: response.fantasyName,
        principal: response.principal,
        email: response.email,
        typeCompany: response.typeCompany,
        foreigner: response.foreigner,
        idCountry: response.idCountry,
        financialPrincipal: response.financialPrincipal,
        financialPrincipalEmail: response.financialPrincipalEmail,
        telephone: response.telephone,
        fiscalAnalysis: response.fiscalAnalysis,
        financialAnalysis: response.financialAnalysis,
        status: response.status,
        typeSupplier: response.typeSupplier,
        taxpayer: response.taxpayer,
        simpleNational: response.simpleNational ? YesNo.YES : YesNo.NO,
        stateInscription: response.stateInscription ?? "",
        municipalInscription: response.municipalInscription ?? "",
        bankId: response.bankAccount?.bank.id,
        bankName: response.bankAccount?.bank.name,
        bankAccount: response.bankAccount?.number,
        bankAccountDigit: response.bankAccount?.digit,
        bankAgency: response.bankAccount?.branch.split("-")[0],
        bankAgencyDigit: response.bankAccount?.branch.split("-")[1],
        bankAccountId: response.bankAccount?.id,
        legalPersonAccount: YesNo.YES,
        isForeign: response.supplierAddresses?.foreigner,
        termsAcceptance: response.termsAcceptance,
        zipCode: response.supplierAddresses?.zipCode ?? "",
        publicPlace: response.supplierAddresses?.publicPlace ?? "",
        number: response.supplierAddresses?.number ?? "",
        complement: response.supplierAddresses?.complement ?? "",
        neighborhood: response.supplierAddresses?.neighborhood ?? "",
        city: response.supplierAddresses?.city ?? "",
        state: response.supplierAddresses?.state ?? "",
        country: response.supplierAddresses?.country ?? "",
        addressId: response.supplierAddresses?.id,
        categories: response.categories,
        products: [],
        businessContacts: response.businessContacts,
        seniority: response.seniority,
      };
      console.log({response})
      setTypeCompany(response.typeCompany);
      setSupplier(data);
      console.log({foreigner})
      setForeigner(response.foreigner);
      console.log('here')
      console.log(response.foreigner)
      form.setFieldsValue(data);
    };

    form.resetFields();
    getSupplier();
  }, [id]);

  const onBlurCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target) return;
    const { value } = e.target;
    const formData = form.getFieldsValue();

    const getInfoAddress = async () => {
      const response = await repositoryCommon.getAddressByBrasilApi(value);
      if (!response) return;

      form.setFieldsValue({
        zipCode: value,
        publicPlace: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state,
        country: "Brasil",
      });
    };

    if (!isView && value.length === 9 && !formData.isForeign) getInfoAddress();
  };

  const updateAddress = async () => {
    const formData = form.getFieldsValue();
    const data: TAddressRequest = {
      id: Number(formData.addressId),
      supplierId: Number(id),
      publicPlace: formData.publicPlace ?? "",
      number: String(formData.number),
      complement: formData.complement ?? "",
      neighborhood: formData.neighborhood ?? "",
      city: formData.city ?? "",
      state: formData.state ?? "",
      country: formData.country ?? "",
      zipCode: formData.zipCode ?? "",
      foreigner: formData.isForeign as boolean
    };

    if (formData.addressId === undefined) {
      const newAddres: TAddressRequest = {
        supplierId: Number(id),
        publicPlace: formData.publicPlace ?? "",
        number: String(formData.number),
        complement: formData.complement ?? "",
        neighborhood: formData.neighborhood ?? "",
        city: formData.city ?? "",
        state: formData.state ?? "",
        country: formData.country ?? "",
        zipCode: formData.zipCode ?? "",
        foreigner: formData.isForeign as boolean
      };

      await repository.createAddress(newAddres);
    } else {
      await repository.updateAddress(data);
    }
  };

  const updateBankAccount = async () => {
    const formData = form.getFieldsValue();
    const agency = formData.bankAgencyDigit
      ? `${formData.bankAgency}-${formData.bankAgencyDigit}`
      : formData.bankAgency;

    const data: TBankAccountRequest = {
      id: Number(formData.bankAccountId),
      supplierId: Number(id),
      number: formData.bankAccount ?? "",
      digit: formData.bankAccountDigit,
      branch: agency ?? "",
      bankId: Number(formData.bankId),
      legalPersonAccount: formData.legalPersonAccount === "YES",
    };

    if (formData.bankAccountId === undefined) {
      const newBank: TBankAccountRequest = {
        supplierId: Number(id),
        number: formData.bankAccount ?? "",
        digit: formData.bankAccountDigit,
        branch: agency ?? "",
        bankId: Number(formData.bankId),
        legalPersonAccount: formData.legalPersonAccount === "YES",
      };
      await repository.createBankAccount(newBank);
    } else {
      await repository.updateBankAccount(data);
    }
  };

  const onFinish = async (values: TSupplierForm) => {

    const data: TSupplierRequest = {
      ...values,
      idCountry: country,
      foreigner: foreigner,
      simpleNational: values.simpleNational === "YES",
      status: values.status,
      seniority: values.seniority,
      cnpj: values.cnpj ? values.cnpj : ""
    };

    const analiseData = {
      supplierId: supplier?.id,
      financialAnalysis: values.financialAnalysis,
      fiscalAnalysis: values.fiscalAnalysis,
    };

    if (!id) {
      const response = await repository.createSupplier(data);
      if (!response) return;
      navigate(`/fornecedores/editar/${response.id}`);
      message.success("Fornecedor criado com sucesso!");
      return;
    }
    if (id && values.status !== SupplierStatus.PRE_REGISTRATION) {
      await updateAddress();
      await updateBankAccount();
    }
    // await updateAddress();
    // await updateBankAccount();

    if (id && values.status === SupplierStatus.HOMOLOGATED) {
      await repository.approveSupplier(analiseData);
    }

    const updatedData = { ...data, id: Number(id) };

    const response = await repository.updateSupplier(updatedData);
    if (!response) return;
    message.success("Fornecedor atualizado com sucesso!");
    onClickGoBack();
  };

  const onClickGoBack = () => {
    navigate("/fornecedores");
  };

  const onSearchBank = async (searchText: string) => {
    if (searchText.length < 3) return;

    const response = await bankRepository.findBanksByFilter({
      name: searchText,
      page: 0,
      size: 10,
    });
    if (!response) return;

    const mappedBankToOptions = response.content.map((item) => ({
      value: item.name,
    }));
    setBanks(response.content);
    setBankOptions(mappedBankToOptions);
  };

  const onChangeBank = (data: string) => {
    setBank(data);
    const findBank = banks.find((_bank) => _bank.name === data);
    if (!findBank) return;

    form.setFieldsValue({
      bankId: findBank.id,
      bankName: findBank.name,
    });
  };

  const onChangeTypeSupplier = (data: any) => {
    if (data.target.value === "SUPPLIER") {
      form.setFieldValue("legalPersonAccount", "YES");
    }
    setTypeSupplier(data.target.value);
  };

  const onChangeNationality = (data: any) => {
    setForeigner(data.target.value)
  };

  const maskPhone = useMemo(() => {
    const removeMaskChar = telephoneWatch?.replace(/_/g, "");
    return removeMaskChar?.length < 15
      ? MaskPhone.TELEPHONE
      : MaskPhone.CELLPHONE;
  }, [telephoneWatch]);

  const { title, isView } = useMemo(() => {
    const [, , page] = pathname.split("/");
    const getTitle = () => {
      if (page === "visualizar") return "Visualizar";
      if (id) return "Editar";

      return "Novo";
    };

    return {
      title: getTitle(),
      isView: page === "visualizar",
    };
  }, [id]);

  const { labelZipCode, maskZipCode } = useMemo(
    () => ({
      labelZipCode: watchedForeign ? "Zip Code" : "CEP",
      maskZipCode: watchedForeign ? "" : "99999-999",
    }),
    [watchedForeign]
  );

  const isPreRegistration = useMemo(
    () => statusWatch === SupplierStatus.PRE_REGISTRATION,
    [statusWatch]
  );

  if (
    authRoles &&
    !hasRole(
      authRoles?.supplier_add ||
        authRoles?.supplier_edit ||
        authRoles?.supplier_view
    )
  ) {
    return <Navigate to="/acesso-negado" />;
  }

  const changeCompanyType = (value: any) => {
    if (value !== "MEI") {
      form.setFieldValue("legalPersonAccount", "YES");
    }
  };
  const onChangeForeigner = (value: any) => {
    setCountries(value)
  };
  const onSearchForeigner = (value: any) => {
    const regex = new RegExp(value, 'i');

    const result = countries.filter(item => regex.test(item.descricao));

    setForeignerOptionsConvert(convertToOptions(result))
  };

  const handleChange = (e: any) => {
    let inputValue = e.target.value;

    inputValue = inputValue?.replace(/\D/g, '');

      setBankAgency(inputValue);
      form.setFieldValue('bankAgency', inputValue)
  }

  return (
    <>
      <PageHeader
        title={`${title} Fornecedor`}
        breadcrumbItems={["Fornecedores", title]}
      />
      <Section spacedTop>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Row>
            <Col xl={16}>
              <Form.Item name="typeSupplier">
                <Radio.Group disabled={isView} onChange={onChangeTypeSupplier}>
                  <Radio value="SUPPLIER">Fornecedor</Radio>
                  <Radio value="FREELANCE">Freelancer</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xl={16}>
              <Row>
                <Form.Item name="foreigner">
                  <Radio.Group
                      disabled={!!id || isView || typeSupplier === "FREELANCE"}
                      onChange={onChangeNationality}
                      value={foreigner}
                  >
                    <Radio value={false} >Brasileiro</Radio>
                    <Radio value={true} >Estrangeiro</Radio>
                  </Radio.Group>
                </Form.Item>
                {!id && foreigner === true ? (
                    <SearchField
                      name="selectForeigner"
                      label="Selecione o pais estrangeiro para o forncedor"
                      onChange={onChangeForeigner}
                      onSearch={onSearchForeigner}
                      options={foreignerOptionsConvert}
                      value={selectForeigner}
                      rules={[
                        {
                          required: true,
                          message: "Selecione o pais de origem ",
                        },
                      ]}
                      required
                    />
                ) : (
                    ""
                )}
              </Row>
            </Col>
          </Row>

          <Row>
            {foreigner === true ?(
              <Col xl={8}>
                <TextField
                    name="TAXID"
                    label="TAXID"
                    disabled
                />
              </Col>
            ) : (
              <Col xl={8}>
                <MaskedField
                  required
                  name="cnpj"
                  label="CNPJ"
                  rules={!foreigner ? validations.cnpj : false}
                  disabled={isView}
                  mask="99.999.999/9999-99"
                />
              </Col>
            )}

            <Col xl={4}>
              <MaskedField
                required={!foreigner}
                name="telephone"
                label="Telefone"
                rules={!foreigner ? validations.telephone : false}
                disabled={isView}
                mask={!foreigner ? maskPhone : false}
              />
            </Col>
            <Col xl={4}>
              <SelectField
                required
                disabled={id ? isView : true}
                name="status"
                label="Status"
                options={id ? supplierStatusOptions : supplierCreationStatusOptions}
              />
            </Col>
            {typeSupplier === "FREELANCE" ||
            supplier.typeSupplier === "FREELANCE" ? (
              <Col xl={4}>
                <SelectField
                  required
                  disabled={isDisabledSeniority || isView}
                  name="seniority"
                  label="Senioridade ?"
                  options={seniorityOptions}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row>
            <Col xl={8}>
              <TextField
                required={!foreigner}
                disabled={isView}
                name="corporateName"
                label="Razão Social"
                rules={!foreigner ? validations.corporateName : []}
              />
            </Col>
            <Col xl={4}>
              <TextField
                required
                disabled={isView}
                name="fantasyName"
                label="Nome Fantasia"
                rules={!foreigner ? [] : validations.fantasyName}
              />
            </Col>
            <Col xl={4}>
              <SelectField
                required
                name="typeCompany"
                label="Tipo de empresa"
                onChange={changeCompanyType}
                disabled={isView}
                options={foreigner === true ? optionsForeigner : optionsNational}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={16}>
              <Divider plain orientation="left">
                Responsáveis
              </Divider>
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <TextField
                required
                disabled={isView}
                name="principal"
                label="Nome"
                rules={validations.principal}
              />
            </Col>
            <Col xl={8}>
              <TextField
                required={!foreigner}
                disabled={isView}
                name="email"
                label="Email"
                extra="Email será usado para enviar o link, onde o fornecedor irá finalizar o cadastro."
                rules={!foreigner ? validations.email : []}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={8}>
              <TextField
                disabled={isView}
                name="financialPrincipal"
                label="Responsável financeiro"
              />
            </Col>
            <Col xl={8}>
              <TextField
                disabled={isView}
                name="financialPrincipalEmail"
                label="Email financeiro"
                rules={validations.financialPrincipalEmail}
              />
            </Col>
          </Row>
          {id && <RelatedProducts supplierId={Number(id)} isView={isView} />}
          {!isPreRegistration && (
            <>
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Informações tributáveis
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <SelectField
                    disabled={isView}
                    name="simpleNational"
                    rules={validations.simpleNational}
                    label="Optante simples nacional"
                    options={[
                      { value: YesNo.YES, label: "Sim" },
                      { value: YesNo.NO, label: "Não" },
                    ]}
                  />
                </Col>
                <Col xl={8}>
                  <SelectField
                    required={!foreigner}
                    disabled={isView}
                    name="taxpayer"
                    label="Tipo"
                    options={taxPayerOptions}
                    rules={!foreigner ? validations.taxpayer : []}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <TextField
                    maxLength={50}
                    disabled={isView}
                    required={!foreigner && watchedTaxPayer === TaxPayer.CONTRIBUTOR}
                    rules={validations.stateInscription}
                    name="stateInscription"
                    label="Inscrição Estadual"
                  />
                </Col>
                <Col xl={8}>
                  <TextField
                    required={!foreigner}
                    maxLength={50}
                    disabled={isView}
                    name="municipalInscription"
                    label="Inscrição Municipal"
                    rules={!foreigner ? validations.municipalInscription : []}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Conta bancária
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <HiddenField name="bankAccountId" />
                  <HiddenField name="bankId" />
                  <SearchField
                    required={!foreigner}
                    name="bankName"
                    label="Banco"
                    disabled={isView}
                    isLoading={repositoryCommon.loading}
                    rules={!foreigner ? validations.bankName : []}
                    value={bank}
                    options={bankOptions}
                    onChange={onChangeBank}
                    onSearch={onSearchBank}
                    placeholder="Pesquise pelo nome do banco"
                  />
                </Col>
                <Col xl={8}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.typeCompany !== currentValues.typeCompany
                    }
                  >
                    {({ getFieldValue }) => (
                      <SelectField
                        required={!foreigner}
                        disabled={
                          isView ||
                          getFieldValue("typeCompany") !== "MEI"
                        }
                        name="legalPersonAccount"
                        label="Conta PJ?"
                        options={yesNoOptions}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <TextField
                    required={!foreigner}
                    disabled={isView}
                    value={bankAgency}
                    name="bankAgency"
                    label="N° Agência"
                    onChange={handleChange}
                    rules={!foreigner ? validations.bankAgency : []}
                    maxLength={50}
                  />
                </Col>
                <Col xl={2}>
                  <TextField
                    disabled={isView}
                    name="bankAgencyDigit"
                    label="N° Dígito"
                    maxLength={1}
                  />
                </Col>
                <Col xl={6}>
                  <TextField
                    required={!foreigner}
                    disabled={isView}
                    name="bankAccount"
                    label="N° Conta"
                    rules={!foreigner ? validations.bankAccount : []}
                    maxLength={50}
                  />
                </Col>
                <Col xl={2}>
                  <TextField
                    disabled={isView}
                    name="bankAccountDigit"
                    label="N° Dígito"
                    maxLength={1}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Endereço
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={16}>
                  <CheckField
                    name="isForeign"
                    label="Estrangeira"
                    disabled={isView}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={4}>
                  <HiddenField name="addressId" />
                  <MaskedField
                    required={!foreigner}
                    disabled={isView}
                    name="zipCode"
                    label={labelZipCode}
                    onBlur={onBlurCep}
                    mask={maskZipCode}
                    rules={!foreigner ? validations.zipCode : []}
                  />
                </Col>
                <Col xl={9}>
                  <TextField
                    required={!foreigner}
                    disabled={isView}
                    name="publicPlace"
                    label="Logradouro"
                    rules={!foreigner ? validations.publicPlace : []}
                  />
                </Col>
                <Col xl={3}>
                  <TextField
                    required={!foreigner}
                    maxLength={10}
                    disabled={isView}
                    name="number"
                    label="Número"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <TextField
                    required={!foreigner}
                    disabled={isView}
                    maxLength={50}
                    name="neighborhood"
                    label="Bairro"
                    rules={!foreigner ? validations.neighborhood : []}
                  />
                </Col>
                <Col xl={8}>
                  <TextField
                    disabled={isView}
                    name="complement"
                    label="Complemento"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <TextField
                    required={!foreigner}
                    disabled={isView}
                    maxLength={50}
                    name="city"
                    label="Cidade"
                    rules={!foreigner ? validations.city : []}
                  />
                </Col>
                <Col xl={4}>
                  <TextField
                    required={!foreigner}
                    maxLength={50}
                    disabled={isView}
                    name="state"
                    label="Estado"
                    rules={!foreigner ? validations.state : []}
                  />
                </Col>
                <Col xl={6}>
                  <TextField
                    maxLength={50}
                    disabled={isView}
                    rules={validations.country}
                    name="country"
                    label="País"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Análise Fiscal e Financeira
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <SelectField
                    required
                    disabled={isView}
                    name="fiscalAnalysis"
                    label="Análise Fiscal"
                    options={analysisSupplierOptions}
                  />
                </Col>
                <Col xl={8}>
                  <SelectField
                    required
                    disabled={isView}
                    name="financialAnalysis"
                    label="Análise Financeira"
                    options={analysisSupplierOptions}
                  />
                </Col>
              </Row>
              <ContactList supplierId={Number(id)} isView={isView} />
              <Attachments supplierId={Number(id)} isView={isView} />
              <Row>
                <Col xl={16}>
                  <Divider plain orientation="left">
                    Termo de aceite
                  </Divider>
                </Col>
              </Row>
              <Row>
                <Col xl={24}>
                  <CheckField
                    name="termsAcceptance"
                    label="Aceito os termos e condições"
                    disabled
                  />
                </Col>
              </Row>
            </>
          )}
          <Form.Item>
            <Space size="small">
              <Button onClick={onClickGoBack} htmlType="button">
                Voltar
              </Button>
              <Button
                loading={repository.loading}
                disabled={isView || repository.loading}
                type="primary"
                htmlType="submit"
              >
                Salvar
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Section>
    </>
  );
};

export default SupplierForm;
