import React, { useEffect, useState } from "react";
import { v4 as genId } from "uuid";
import {
  usePurchaseOrder,
  TPurchaseOrder,
  TInfoUser,
  TMemberChat,
  TChatOutputDTO,
  TCreationUserDTO,
  TSelectedQuestionateButtonData,
  TBuyerDTO,
} from "repositories";
import { BiChevronDown } from "react-icons/bi";
import {
  Spin,
  Button,
  Space,
  Empty,
  Pagination,
  Card,
  Table,
  Typography,
  Divider,
  MenuProps,
  Tooltip,
  Tag,
  message,
} from "antd";

import { Modal as ModalRequests } from "antd";
import Modal from "../../../Tracker/Orders/Components/Modal";
import { useAuth, useFilters, usePagination } from "hooks";
import { FlexEndContainer, Section } from "components";
import {
  formatCurrency,
  formatDateToServer,
  formatDateToView,
} from "utils/helpers";
import Chat from "pages/Components/Chat";
import {Flex, StyledRow, YellowButton, Tarja} from "./styles";
import {
  purchaseOrderStatus,
  supplierStatus,
  purchaseOrderStatusColor,
} from "utils/constants";
import {
  Box,
  ButtonBox,
  Content,
  SummaryActions,
  Info,
  Label,
  AdditionalInfo,
  Abbreviation,
  InfoOrder,
} from "./styles";
import FiltersForm from "./Components/FiltersForm";
import AcceptOrRefusedForm from "./Components/AcceptOrRefusedForm";
import UserHierarchy from "../UserHierarchy";
import ProjectAndLineInformation from "../ProjectAndLineInformation";

import e from "../../../../assets/icons/ofensores/e.svg";
import eD from "../../../../assets/icons/ofensores/e-disabled.svg";

import porcentagem from "../../../../assets/icons/ofensores/porcentagem.svg";
import porcentagemD from "../../../../assets/icons/ofensores/porcentagem-disabled.svg";

import card from "../../../../assets/icons/ofensores/card.svg";
import cardD from "../../../../assets/icons/ofensores/card-disabled.svg";

import stars from "../../../../assets/icons/ofensores/starss.svg";
import starsD from "../../../../assets/icons/ofensores/starss-disabled.svg";

import calendarDay from "../../../../assets/icons/ofensores/calendar-day.svg";
import calendarDayD from "../../../../assets/icons/ofensores/calendar-day-disabled.svg";

import time from "../../../../assets/icons/ofensores/time.svg";
import timeD from "../../../../assets/icons/ofensores/time-disabled.svg";

import calendar from "../../../../assets/icons/ofensores/calendar.svg";
import calendarD from "../../../../assets/icons/ofensores/calendar-disabled.svg";

import cash from "../../../../assets/icons/ofensores/cash.svg";
import cashD from "../../../../assets/icons/ofensores/cash-disabled.svg";

import moment from "moment";

import { useRequest, useProject } from "repositories";
import Timeline from "./Components/Timeline";
import ApportionmentInformation from "../ApportionmentInformation";

import Popover from "../../../Tracker/Components/Popover";

const { Text } = Typography;

export const getOrderNames = (order: TPurchaseOrder) => {
  const setNames = new Set();
  order.items.forEach((item) => {
    if (order.isRequestNotTiedToProject)
      setNames.add(
          `${item.costCenter?.erpId} - ${item.costCenter?.description}`
      );
    else {
      setNames.add(`${item.projectIdErp} - 
    ${item.projectName} - 
    ${item.clientName}`);
    }
  });

  return setNames as Set<string>;
};

const OrdersTab = () => {
  const [orders, setOrders] = useState<any>();
  const [supplier, setSupplier] = useState([]);
  const [projectRequests, setProjectRequests] = useState([]);
  const [isVisibleFilterForm, setIsVisibleFilterForm] = useState(false);
  const [isActiveApproval, setIsActiveApproval] = useState<boolean>();
  const [isVisibleAcceptOrRefuseForm, setIsVisibleAcceptOrRefuseForm] =
    useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState(0);
  const [member, setMember] = useState<TMemberChat>({} as TMemberChat);
  const [isVisibleChat, setIsVisibleChat] = useState(false);
  const { userData } = useAuth();

  const [statusToggle, setStatusToggle] = useState<any>(false)

  const [requestModal, setRequestModal] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const repository = useRequest();

  const filters = useFilters();
  const purchaseRepository = usePurchaseOrder();
  const pagination = usePagination();

  const requestsColumns: any = [
    {
      title: "Emissão",
      dataIndex: "issueDate",
      render: (_: any, record: any) => {
        return record.issueDate
          ? moment(record.issueDate).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      render: (_: any, record: any) => {
        return record.maturity
          ? moment(record.maturity).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Produto",
      dataIndex: "productName",
    },
    {
      title: "Prestador",
      dataIndex: "typeProvider",
      render: (_: any, record: any) => {
        return record.typeProvider === "SUPPLIER" ? "Fornecedor" : "Freelancer";
      },
    },
    {
      title: "Tipo",
      dataIndex: "typeRequest",
      render: (_: any, record: any) => {
        if (record.typeRequest === "SIMPLE") return "Simples";
        if (record.typeRequest === "COMPOSED") return "Composta";
        return "Composta";
      },
    },
    {
      title: "Categoria",
      dataIndex: "category",
    },
    {
      title: "Alocação",
      dataIndex: "allocation",
    },
    {
      title: "Local",
      dataIndex: "buName",
    },
    {
      title: "Cliente",
      dataIndex: "clientName",
    },
    {
      title: "Timing",
      dataIndex: "timing",
      render: (_: any, record: any) => {
        if (record.timing === "PLANEJADA")
          return <Tag color="green">Planejada</Tag>;

        return <Tag color="red">Não planejada</Tag>;
      },
    },
    {
      title: "Fatura",
      dataIndex: "paymentType",
    },
    {
      title: "Valor da requisição",
      dataIndex: "totalCost",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: any) => {
        switch (record.status) {
          case "NEW":
            return <Tag color="cyan">Novo</Tag>;
          case "IN_QUOTATION":
            return <Tag color="yellow">Em cotação</Tag>;
          case "APPROVED":
            return <Tag color="green">Aprovado</Tag>;
          case "REFUSED":
            return <Tag color="orange">Reprovada</Tag>;
          case "CANCELED":
            return <Tag color="red">Cancelada</Tag>;
          case "ON_APPROVAL":
            return <Tag color="yellow">Em aprovação</Tag>;
          case "PAID_OUT":
            return <Tag color="green">Pago</Tag>;
          default:
            return record.status;
        }
      },
    },
  ];

  const formatFilter = (_filters: any) => {
    if (_filters.startDateApprover) {
      _filters.startDateApprover = formatDateToServer(
        _filters.startDateApprover
      );
    }
    if (_filters.endDateApprover) {
      _filters.endDateApprover = formatDateToServer(_filters.endDateApprover);
    }
    return _filters;
  };

  const getPurchases = async () => {
    const currentPage = pagination.data.current ?? 0;
    const _filters = filters.getObjectifiedFilterItems();
    const formatedFilters = formatFilter(_filters);
    const requestParams = {
      page: currentPage === 0 ? currentPage : currentPage - 1,
      size: pagination.data.pageSize ?? 1,
      sort: "id,DESC",
      activeApproval: isActiveApproval,
      ...formatedFilters,
    };
    const response = await purchaseRepository.getPurchaseOrderInAnalysis(
      requestParams
    );
    if (!response) return;
    if (response.content.length === 0)
      message.warn("Você não possui requisições no momento");

    pagination.changeTotalPages(response.totalPages);
    let supplierAndProduct: any = [];
    let projectRequest: any = [];

    response?.content?.forEach((c) => {
      supplierAndProduct = [
        ...supplierAndProduct,
        {
          orderId: c.id,
          supplierId: c.supplierId,
          supplierType: c.supplierType,
          productName: c.productName,
          productId: c.productId,
        },
      ];
      projectRequest = [
        ...projectRequest,
        {
          orderId: c.id,
          projectRequest: c.projectRequest,
        },
      ];
    });

    setSupplier(supplierAndProduct);
    setProjectRequests(projectRequest);
    setOrders(response.content);
  };

  const onClickGoToDetail = (orderId?: number) => () => window.open(`/requisicao/visualizar/${orderId}`);

  const onClickOpenFilterForm = () => {
    setIsVisibleFilterForm(true);
  };

  const onClickAcceptOrRefuse =
    (orderId: number, accept = true) =>
    () => {
      setPurchaseOrderId(orderId);
      setIsVisibleAcceptOrRefuseForm(true);
      setIsAccept(accept);
    };

  const getChat = (chats: TChatOutputDTO[], usersId: number[]) =>
    chats?.find((chat) =>
      chat.users.every((user) => usersId.includes(user.id))
    );

  const makeMemberChat = (
    chat: TChatOutputDTO | undefined,
    userToId: number,
    userToName: string,
    userToDescription?: string
    // eslint-disable-next-line max-params
  ): TMemberChat => ({
    key: genId(),
    chatId: chat?.id || 0,
    messagesNotViewed: chat?.messagesNotViewed || 0,
    userFromId: userData?.userId,
    userFromName: userData?.userName,
    userToId,
    userToName,
    userToDescription,
  });

  const getMemberToCreationUser = (
    userFrom: TInfoUser,
    creationUser: TCreationUserDTO,
    chats: TChatOutputDTO[]
  ) => {
    const findedChat = getChat(chats, [
      userFrom?.userId,
      creationUser.creationUserId,
    ]);
    if (!creationUser) return {} as TMemberChat;

    return makeMemberChat(
      findedChat,
      creationUser.creationUserId,
      creationUser.creationUserName
    );
  };

  const getMemberToBuyer = (
    userFrom: TInfoUser,
    buyer: TBuyerDTO,
    chats: TChatOutputDTO[]
  ) => {
    if (buyer.buyerId && buyer.buyerName) {
      const findedChat = getChat(chats, [userFrom?.userId, buyer.buyerId]);
      if (!buyer) return {} as TMemberChat;
      return makeMemberChat(findedChat, buyer.buyerId, buyer.buyerName);
    }
  };

  const handleOpenQuestionateChat = (
    openChatData: TSelectedQuestionateButtonData
  ) => {
    setMember(openChatData.requester);
    if (openChatData.purchaseOrder) {
      setPurchaseOrderId(openChatData.purchaseOrder);
    }
    setIsVisibleChat(true);
  };

  const handleOpenChat: MenuProps["onClick"] = (e) => {
    const [user, purchaseOrder] = e.key.split("|");
    const objectifyUser = JSON.parse(user);
    setMember(objectifyUser as TMemberChat);
    setPurchaseOrderId(Number(purchaseOrder));
    setIsVisibleChat(true);
  };

  const getSupplierStatus = (status: any) => {
    switch (status) {
      case "APPROVED":
        return (
          <Label className="label" color="green">
            Homologado
          </Label>
        );
        break;

      case "NOT_APPROVED":
        return (
          <Label className="label" color="red">
            Bloqueado
          </Label>
        );
        break;

      default:
        return (
          <Label className="label" color="blue">
            {supplierStatus[status]}
          </Label>
        );
        break;
    }
  };

  const openRequests = async (order: any) => {
    const params: any = {
      page: 0,
      size: 2000,
      sort: "issueDate,DESC",
      supplierIdCnpj: order.supplierId,
    };
    const response = await repository.findRequests(params);
    if (!response) return;

    const mappedResponse = response.content.map((request: any) => {
      return {
        ...request,
        productName: request?.items[0]?.productDescription,
        buName: request.items[0].projectRevision?.project.buName,
        clientName: request.items[0].projectRevision?.project.clientName,
        totalCost: formatCurrency(request?.items[0]?.totalCost),
        status: request.statusRequest,
        allocation: request.items[0].projectRevision.project.idERP
      };
    });
    setRequestModal(mappedResponse);
    setIsModalOpen(true);
  };

  const updateList = () => {
    getPurchases();
  };

  useEffect(() => {
    if (filters.items && pagination.data.current) getPurchases();
  }, [pagination.data.current, pagination.data.pageSize, filters.items, isActiveApproval]);

  const resetPagination = () => {
    pagination.onChange(1, pagination.data.pageSize ?? 10);
  };

  return (
    <>
      <StyledRow>
        <ButtonBox >
          <YellowButton  onClick={() =>{ setIsActiveApproval(!isActiveApproval) }}>{isActiveApproval ? 'Exibir tudo' : 'Filtrar pendências'}</YellowButton>
        </ButtonBox>
        <ButtonBox>
          <Button onClick={onClickOpenFilterForm}>Filtros</Button>
        </ButtonBox>
      </StyledRow>
      {!orders?.length && !purchaseRepository.loading && (
        <Card className="mt-1">
          <Empty description="Nenhum pedido de compra encontrado" />
        </Card>
      )}
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Spin tip="Carregando" spinning={purchaseRepository.loading}>
          {orders?.map((order: any) => {
            const projectRequestOrder = projectRequests.filter(
              (item: any) => item.orderId === order.id
            );

            const formattedPurchaseValue = formatCurrency(order.purchaseValue);
            return (
              <Box key={order?.id}>
                <Content>
                  {order.projectRequest.typeRequest === "COMPOSED" ? (
                      <div>
                        <Tarja>
                          Requisição composta [Rateio e Contrato]
                        </Tarja>
                      </div>
                  ) : (
                      ""
                  )}
                  <br/>
                  <Info>
                    <div style={{ paddingBottom: "10px" }}>
                      <div className="wrapper">
                        <Label color={purchaseOrderStatusColor[order?.status]}>
                          {purchaseOrderStatus[order?.status]}
                        </Label>
                        {order.projectRequest.typeRequest === "COMPOSED" ? (
                            <Label color="black">Composta</Label>
                        ) : (
                            <Label color="gray">Simples</Label>
                        )}
                        <Label color="gray">Compra e contratação</Label>
                        <Label color="gray">Com projeto</Label>
                        {/*Tag verba so deve aparecer se não for vinculada a projeto*/}
                        {!order.projectRequest.id ? (
                          <Label color="gray">Verba TV1</Label>
                        ) : (
                          ""
                        )}
                      </div>
                      <Popover setNames={getOrderNames(order)} title="Projetos" />
                    </div>

                    <div style={{ display: "initial", textAlign: "right" }}>
                      <p>{formatCurrency(order.purchaseValue)}</p>
                      <span>{`Emissão: ${formatDateToView(
                        order.issueDate
                      )}`}</span>
                      <br />
                      {
                        order?.projectRequest?.typeRequest === "COMPOSED" ?
                        <span>{`1º Vencim.: ${formatDateToView(order?.items[0]?.apportions[0]?.dueDate)}`}</span>
                        :
                        <span>{`Vencim.: ${formatDateToView(order?.projectRequest?.maturity)}`}</span>
                      }
                    </div>
                  </Info>
                  <AdditionalInfo>
                    <div>
                      <Abbreviation>
                        <span>
                          {
                            order?.projectRequest?.items[0]?.projectRevision
                              ?.project?.buName
                          }
                        </span>
                        <Label
                          className="label"
                          color={
                            order?.projectRequest?.approvalHierarchyTiming ===
                            "PLANEJADA"
                              ? "blue"
                              : "red"
                          }
                        >

                          {order?.projectRequest?.approvalHierarchyTiming ===
                          "PLANEJADA"
                            ?
                            <Tooltip title="Requisição planejada">
                              PL
                            </Tooltip>
                            :
                            <Tooltip title="Requisição não planejada">
                              NP
                            </Tooltip>
                          }
                        </Label>
                        <span>{order?.projectRequest?.id}</span>
                        <Button onClick={() => setStatusToggle(order?.id)} type="link">{order?.items[0]?.apportions[0]?.idErpCorrelation}</Button>
                      </Abbreviation>
                      <InfoOrder>
                        <span className="productTitle">
                          {
                            order?.items[0]?.projectRevisionItem
                              ?.productDescription
                          }
                        </span>
                        <div>
                          <div className="wrapper">
                            <Label className="label" color="gray">
                              {order?.supplierType === "SUPPLIER"
                                ? "Fornecedor"
                                : "Freelancer"}
                            </Label>
                            {getSupplierStatus(order?.supplierStatus)}
                          </div>
                          <span>{order?.supplierFantasyName}</span>
                        </div>
                        <div>
                          <Label className="label" color="green">
                            Requisitante
                          </Label>
                          <p>{order?.creationUserName}</p>
                        </div>
                      </InfoOrder>
                    </div>
                    <div>
                      <Button
                        onClick={onClickGoToDetail(order?.projectRequest.id)}
                      >
                        Detalhes
                      </Button>
                    </div>
                  </AdditionalInfo>
                  <Divider />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      gap: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    {order?.offenders?.emergencyRequest ? (
                      <Tooltip title="Requisição não planejada">
                        <img src={e} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={eD} alt="" />
                    )}

                    {order?.offenders?.projectLowerMargin ? (
                      <Tooltip title="Projeto com margem a baixo do esperado">
                        <img src={porcentagem} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={porcentagemD} alt="" />
                    )}

                    {order?.offenders?.supplierWithoutApproval ? (
                      <Tooltip title="Fornecedor sem homologação">
                        <img src={card} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={cardD} alt="" />
                    )}

                    {order?.offenders?.lowRatedSupplier ? (
                      <Tooltip title="Fornecedor com baixa avaliação">
                        <img src={stars} alt="" />
                      </Tooltip>
                    ) : (
                      <img
                        src={starsD}
                        alt=""
                        style={{ filter: "brightness(0.6)" }}
                      />
                    )}

                    {order?.offenders?.startWorkRequestDate ? (
                      <Tooltip title="Inicio de trabalho vs data requisição">
                        <img src={calendarDay} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={calendarDayD} alt="" />
                    )}
                    {order?.offenders?.requestsForSameCNPJLastDays ? (
                      <Tooltip title="Atividade Recente (CNPJ)">
                        <img
                          src={time}
                          alt=""
                          onClick={() => openRequests(order)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    ) : (
                      <img src={timeD} alt="" />
                    )}

                    {order?.offenders?.paymentTerm ? (
                      <Tooltip title="Prazo de pagamento">
                        <img src={calendar} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={calendarD} alt="" />
                    )}

                    {order?.offenders?.preciary ? (
                      <Tooltip title="Preciario">
                        <img src={cash} alt="" />
                      </Tooltip>
                    ) : (
                      <img src={cashD} alt="" />
                    )}
                  </div>
                </Content>
                <Content>
                  <UserHierarchy order={order || []} />
                  <br/>
                  <ProjectAndLineInformation
                    projectRequest={projectRequestOrder || []}
                    typeRequest={order?.projectRequest?.typeRequest}
                  />
                  {
                    order?.projectRequest?.typeRequest === 'COMPOSED' &&
                    <ApportionmentInformation
                      data={projectRequestOrder[0] || []}
                      record={order}
                      toggle={statusToggle}
                      resetStatusToggle={setStatusToggle}
                    />
                  }
                </Content>
                <Content>
                  <SummaryActions>
                    <span>Valor da requisição</span>
                    <p>{formattedPurchaseValue}</p>
                    <Space size="small" style={{ padding: "0 15px" }}>
                      <Button
                        type="primary"
                        size="small"
                        onClick={onClickAcceptOrRefuse(Number(order.id), true)}
                        disabled={
                          order.status === "REFUSED" || !order.currentApprover
                        }
                      >
                        Aprovar
                      </Button>
                      <Button
                        type="dashed"
                        size="small"
                        onClick={onClickAcceptOrRefuse(Number(order.id), false)}
                        disabled={
                          order.status === "REFUSED" || !order.currentApprover
                        }
                        danger
                      >
                        Reprovar
                      </Button>
                    </Space>
                    <Space size="small" style={{ marginTop: 16 }}>
                      {order.questionateButton}
                    </Space>
                    {order.status === "REFUSED" || order.status === "CANCELED" ? (
                      <Button type="link" disabled>
                        <Flex>
                          <span>Questionar</span>
                          <BiChevronDown />
                        </Flex>
                      </Button>
                    ) : (
                      <Modal order={order} updateList={updateList} />
                    )}
                  </SummaryActions>
                </Content>
              </Box>
            );
          })}
        </Spin>
      </Section>

      <FlexEndContainer className="mt-1">
        <Pagination onChange={pagination.onChange} {...pagination.data} />
      </FlexEndContainer>
      <Chat
        member={member}
        setMember={setMember}
        purchaseOrderId={purchaseOrderId}
        isVisible={isVisibleChat}
        setIsVisible={setIsVisibleChat}
        refreshCallback={getPurchases}
      />
      <AcceptOrRefusedForm
        accept={isAccept}
        purchaseOrderId={purchaseOrderId}
        isVisibleModal={isVisibleAcceptOrRefuseForm}
        setIsVisibleModal={setIsVisibleAcceptOrRefuseForm}
        requestFn={getPurchases}
      />
      <FiltersForm
        resetPagination={resetPagination}
        filters={filters}
        isVisibleDrawer={isVisibleFilterForm}
        setIsVisibleDrawer={setIsVisibleFilterForm}
      />
      <ModalRequests
        title="Requisições"
        className="horizontal-scrollable-modal"
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        width={1300}
      >
        <Table
          columns={requestsColumns}
          dataSource={requestModal}
          pagination={false}
          style={{ overflowX: "auto" }}
        />
      </ModalRequests>
    </>
  );
};

export default OrdersTab;
